import React from "react";
import { Grid } from "@mui/material";
import Sidebar from "../component/Sidebar";
import MainHeader from "../component/MainHeader";
import Footer from "../component/footer/Footer";

const MainLayout = ({ children }) => {
  return (
    <Grid
      container
      direction="column"
      style={{
        minHeight: "100vh",
        backgroundColor: "rgb(250, 250, 250)",
        overflow: "hidden",
      }}
    >
      <Grid item style={{ flex: "0 0 auto" }}>
        <MainHeader />
      </Grid>
      <Grid item container style={{ flex: "1 1 auto" }}>
        <Grid item xs={12} md={3} lg={2.5}>
          <Sidebar />
        </Grid>
        <Grid 
          item 
          xs={12} 
          md={9} 
          lg={9.5} 
          style={{ 
            height: "calc(100vh - 64px - 56px)", 
            overflowY: "auto",
            overflowX: "hidden", 
            scrollbarWidth: "none", 
          }}
        >
          {children}
        </Grid>
      </Grid>
      <Grid item style={{ flex: "0 0 auto" }}>
        <Footer />
      </Grid>
    </Grid>
  );
};

export default MainLayout;
