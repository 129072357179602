import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { Typography, Grid, Paper } from "@mui/material";
import Box from "@mui/material/Box";
import MainLayout from "../../layout/MainLayout";
import LeadDetailReq from "../../component/LeadDetailReq/LeadDetailReq";
import LeadDetailHistory from "../../component/LeadDetailHistory/LeadDetailHistory";
import { Link } from "react-router-dom";
import arrowBack from "../../assets/arrowBack.svg"

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box
          sx={{ p: 3 }}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function VPDetails() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <MainLayout>
      <Grid>
        <Grid
          style={{
            display: "flex",

            alignItems: "center",
            padding: "1rem",
          }}
        >
          <Typography style={{ fontWeight: 700, fontSize: "30px" }}>
            Leads Management
          </Typography>
        </Grid>
        <Grid style={{ marginLeft: "15px", marginBottom: "15px" }}>
          <Paper elevation={0} style={{ padding: "1rem" }}>
            <Link to={"/leadsmanagement"}>
              <img
                src={arrowBack}
                alt="arroWback"
                style={{
                  width: "2.813rem",
                  height: "2.813rem",
                  cursor: "pointer",
                }}
              />
            </Link>
            <Box sx={{ width: "100%" }}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                >
                  <Tab
                    label={
                      <Typography
                        sx={{
                          textTransform: "none",
                          color: value === 0 ? "rgba(0, 176, 80, 1)" : "#ccc",
                        }}
                      >
                        Leads Info
                      </Typography>
                    }
                    {...a11yProps(0)}
                  />
                  <Tab
                    label={
                      <Typography
                        sx={{
                          textTransform: "none",
                          color: value === 1 ? "rgba(0, 176, 80, 1)" : "#ccc",
                        }}
                      >
                        History
                      </Typography>
                    }
                    {...a11yProps(1)}
                  />
                </Tabs>
              </Box>
              <CustomTabPanel value={value} index={0}>
                <LeadDetailReq status={"Booked Visit"} />
              </CustomTabPanel>
              <CustomTabPanel value={value} index={1}>
                <LeadDetailHistory />
              </CustomTabPanel>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </MainLayout>
  );
}
