import React, { useState } from "react";
import MainLayout from "../../../layout/MainLayout";
import { Paper, TextField, Typography, Grid } from "@mui/material";
import { Link, useNavigate, useParams } from "react-router-dom";
import ButtonComponent from "../../../component/Button";
import { Formik, Form, Field, ErrorMessage } from "formik";
import arrowBack from "../../../assets/arrowBack.svg";
import { getAPIHandler, putAPIHandler } from "../../../config/service";
import { developerSchema } from "../../../schemas";
import { toast } from "react-hot-toast";
import BubbleLoader from "../../../component/Loader/BubbleLoader";

const EditDeveloper = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});

  const navigate = useNavigate();

  const { id } = useParams();

  const onSubmit = async (values, { setSubmitting }) => {
    setLoading(true)
    try {
      const response = await putAPIHandler({
        endPoint: "updateDeveloper",
        dataToSend: {
          developerId: id,
          fullName: values.developerName,
        },
      });

      console.log(response.data.result);
      setSubmitting(false);
      if (response && response.data.responseCode === 200) {
        toast.success("Developer updated successfully.");
        navigate("/developermanagement");
      } 

    } catch (error) {
      console.error("Error:", error);
      setSubmitting(false);
    }
    setLoading(false)
  };

  const getData = async () => {
    try {
      const response = await getAPIHandler({
        endPoint: "getDeveloperById",
        paramsData: {
          developerId: id,
        },
      });

      console.log("Response:", response.data);

      setData(response.data.result);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  React.useEffect(() => {
    getData();
  }, []);

  return (
    <MainLayout>
      <Grid>
        <Grid
          style={{
            display: "flex",

            alignItems: "center",
            padding: "1rem",
          }}
        >
          <Typography style={{ fontWeight: 700, fontSize: "30px" }}>
            Developer Management
          </Typography>
        </Grid>
        <Grid style={{ marginBottom: "15px", marginLeft: "15px" }}>
          <Paper elevation={0} style={{ padding: "15px" }}>
            <div>
              <div className="arrow">
                <Link to={"/developermanagement"}>
                  <img
                    src={arrowBack}
                    alt="arroWback"
                    style={{
                      width: "2.813rem",
                      height: "2.813rem",
                      cursor: "pointer",
                    }}
                  />
                </Link>
                <Typography
                variant="h5"
                sx={{ marginLeft: "1rem", fontWeight: 800 }}
              >
               Update Developer
              </Typography>
              </div>

              <div className="content-container">
                <Formik
                  initialValues={{
                    developerName: data ? data.fullName : "",
                  }}
                  enableReinitialize={true}
                  validationSchema={developerSchema}
                  onSubmit={onSubmit}
                >
                  {(formik) => (
                    <Form>
                      <div>
                        <div className="developer-title">
                          <label htmlFor="developerName">
                            <strong>
                              Developer Name{" "}
                              <span style={{ color: "red" }}>*</span> :{" "}
                            </strong>
                          </label>
                          <Field
                            as={TextField}
                            type="text"
                            name="developerName"
                            variant="outlined"
                            className="form-control"
                            onKeyPress={(e) => {
                              if (e.target.value.length === 0) {
                                if (e.key === " ") e.preventDefault();
                              }
                              if (
                                e.target.value.length >= 60 &&
                                e.key !== "Backspace"
                              ) {
                                e.preventDefault();
                              }
                            }}
                          />
                        </div>

                        <ErrorMessage
                          name="developerName"
                          component="div"
                          className="errorr"
                        />
                      </div>

                      <div className="button-container">
                        <ButtonComponent
                          height="43px"
                        >{loading ? <BubbleLoader color="#FFF" size="0.7rem" /> : "Update Developer"}</ButtonComponent>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </Paper>
        </Grid>
      </Grid>
    </MainLayout>
  );
};

export default EditDeveloper;
