import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import MainLayout from "../../../layout/MainLayout";
import { Paper, Typography, Box , CircularProgress} from "@mui/material";
import arrowBack from "../../../assets/arrowBack.svg";
import Input from "@mui/joy/Input";
import Textarea from "@mui/joy/Textarea";
import "./AddManager.css";
import Select from "react-select";
import ButtonComponent from "../../../component/Button";
import { IoIosAddCircle, IoIosRemoveCircle } from "react-icons/io";
import { Link, useNavigate } from "react-router-dom";
import { getAPIHandler, postAPIHandler } from "../../../config/service";
import { managerSchema } from "../../../schemas";
import { State, City } from "country-state-city";
import { toast } from "react-hot-toast";
import typographyStyles from "../../../theme/Typography";
import defaultProfileIcon from "../../../assets/profileImg.png";
import editIcon from "../../../assets/Edit.png";


const accountStyle = {
  editAccountContainer: {
    marginTop: "20px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  accountTitle: {
    ...typographyStyles.h4,
  },
  accountLabel: {
    ...typographyStyles.h6,
  },
  editAccountContent: {
    display: "flex",
    justifyContent: "center",
    paddingTop: "20px",
  },
  editAccountMainHeading: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
  },
  accountHeading: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "1%",
    gap: "1%",
  },
  editAccountImgSection: {
    paddingRight: "20px",
    borderRight: "1px solid rgba(0, 0, 0, 0.2)",
  },
  loader: {
    position: "absolute",
    top: "40%",
    left: "40%",
    transform: "translate(-25%, -25%)",
    color: "rgba(0, 176, 80, 1)",
  },
  editIcon: {
    transform: "translate(-25%, -25%)",
    cursor: "pointer",
  },
  editAccountInfoSection: {
    width: "60%",
    paddingLeft: "20px",
    display: "flex",
    flexDirection: "column",
    gap: "20px",
  },
  editAccountinfoBox: {
    display: "flex",
    flexDirection: "column",
    gap: "4px",
  },
  editAccountBtnSection: {
    padding: "0 20%",
    display: "flex",
    gap: "5%",
  },
};

const AddManager = () => {
  const navigate = useNavigate();
  const [stateOptions, setStateOptions] = useState([]);
  const [cityOptions, setCityOptions] = useState([]);
  const [vpOptions, setVpOptions] = useState([]);
  const [propertyOptions, setPropertyOptions] = useState([]);
  const [additionalProjects, setAdditionalProjects] = useState([]);
  const [showAdditionalProjects, setShowAdditionalProjects] = useState(false);
  const [profileImage, setProfileImage] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchStateOptions();
    fetchUserOptions();
    fetchPropertyOptions();
  }, []);

  const fetchStateOptions = () => {
    const states = State.getStatesOfCountry("IN").map((state) => ({
      value: state.isoCode,
      label: state.name,
    }));
    setStateOptions(states);
  };

  const fetchUserOptions = async () => {
    try {
      const vpResponse = await getAPIHandler({
        endPoint: "listUserDetailsByType",
        paramsData: { userType: "VP" },
      });

      const mapUsers = (users) =>
        users.map((user) => ({
          value: user._id,
          label: `${user.firstName} ${user.lastName}`,
          role: user.userRoleType,
        }));

      setVpOptions(mapUsers(vpResponse.data.result.userDetails));
    } catch (error) {
      console.error("Error fetching user options:", error);
    }
  };

  const fetchPropertyOptions = async () => {
    try {
      const propertyResponse = await getAPIHandler({
        endPoint: "getAllProperty",
      });

      const mapProperties = (properties) =>
        properties.map((property) => ({
          value: property._id,
          label: property.propertyName,
        }));

      setPropertyOptions(mapProperties(propertyResponse.data.result.property));
    } catch (error) {
      console.error("Error fetching property options:", error);
    }
  };

  const handleStateChange = (selectedState, setFieldValue) => {
    if (!selectedState) {
      setCityOptions([]);
      setFieldValue("city", "");
      return;
    }

    const cities = City.getCitiesOfState("IN", selectedState.value).map(
      (city) => ({
        value: city.name,
        label: city.name,
      })
    );
    setCityOptions(cities);
    setFieldValue("city", "");
  };

  const handleRemoveProject = (index) => {
    const updatedProjects = additionalProjects.filter((_, i) => i !== index);
    setAdditionalProjects(updatedProjects);
  };

  const handleAddProject = () => {
    setAdditionalProjects([...additionalProjects, { project: "" }]);
    setShowAdditionalProjects(true);
  };

  const handleProjectChange = (index, selectedOption, setFieldValue) => {
    const updatedProjects = [...additionalProjects];
    updatedProjects[index] = {
      project: selectedOption ? selectedOption.value : "",
    };
    setAdditionalProjects(updatedProjects);
    setFieldValue(
      `additionalProjects[${index}]`,
      selectedOption ? selectedOption.value : ""
    );
  };

  const handleProfileImageChange = async (event) => {
    const file = event.currentTarget.files[0];
    if (file) {
      if (file.type.startsWith("image/")) {
        try {
          setLoading(true);
          const formData = new FormData();
          formData.append("file", file);

          const res = await postAPIHandler({
            endPoint: "uploadFile",
            dataToSend: formData,
          });

          setProfileImage(res.data.result);
          toast.success("Image uploaded successfully!");
        } catch (error) {
          console.error("Error uploading file:", error);
          toast.error("Failed to upload image.");
        } finally {
          setLoading(false);
        }
      } else {
        toast.error("Please select a valid image file.");
      }
    }
  };

  return (
    <MainLayout>
      <Box style={{ padding: "1rem" }}>

        <Box sx={accountStyle.editAccountMainHeading}>
          <Typography style={{ fontWeight: 700, fontSize: "30px" }}>
            Manager Management
          </Typography>
        </Box>


       
          <Paper
            elevation={0}
           
          >
            <Box sx={accountStyle.accountHeading}>
              <Link to={"/managermanagement"}>
                <img
                  src={arrowBack}
                  alt="arroWback"
                  style={{
                    width: "2.813rem",
                    height: "2.813rem",
                    cursor: "pointer",
                  }}
                />
              </Link>
              <Typography
                variant="h5"
                sx={{ marginLeft: "1rem", fontWeight: 800 }}
              >
                Add Manager
              </Typography>
            </Box>



            <Box display="flex" justifyContent="center" margin="2rem">
              <Typography
                variant="h6"
                sx={{ marginLeft: "1rem", fontWeight: 700 }}
              >
                Member Details
              </Typography>
            </Box>
            

            <Box sx={accountStyle.editAccountContainer}> <Formik
            initialValues={{
              roleType: "",
              firstName: "",
              lastName: "",
              email: "",
              phoneNumber: "",
              state: "",
              city: "",
              address: "",
              vp: "",
              project: "",
              additionalProjects: [],
              profileImage: "",
            }}
            validationSchema={managerSchema}
            onSubmit={async (values, { setSubmitting }) => {
              if (!profileImage) {
                toast.error("Please upload a profile image.");
                setSubmitting(false);
                return;
              }
              try {
                const assignedProperty = [
                  values.project,
                  ...values.additionalProjects,
                ].filter(Boolean);
                const dataToSend = {
                  userType: "MANAGER",
                  userRoleType: values.roleType,
                  firstName: values.firstName,
                  lastName: values.lastName,
                  email: values.email,
                  mobileNumber: values.phoneNumber,
                  state: values.state,
                  city: values.city,
                  address: values.address,
                  vpId: values.vp,
                  profileImage: profileImage,
                  assignedProperty:
                    values.roleType === "PRE_SALES" ? assignedProperty : [],
                };
                const response = await postAPIHandler({
                  endPoint: "userSignup",
                  dataToSend,
                });

                if (response && response.data.responseCode === 200) {
                  toast.success("Manager added successfully.");
                  navigate("/managermanagement");
                } else {
                  toast.error("User already exists.");
                }
              } catch (error) {
                console.error("Error submitting form:", error);
                setSubmitting(false);
              }
            }}
          >
            {({ isSubmitting, setFieldValue, handleBlur, values }) => (
              <Form style={accountStyle.editAccountContent}>


              <Box sx={accountStyle.editAccountImgSection}>
              <label
                htmlFor="upload-image"
                style={{
                  cursor: "pointer",
                  display: "inline-block",
                  position: "relative",
                }}
              >
                {loading && (
                  <CircularProgress
                    size={50}
                    style={{
                      position: "absolute", // Position the loader absolutely within the image container
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)", // Center the loader
                    }}
                  />
                )}
                {/* Conditional Rendering for Profile Image or Default Icon */}
                {!loading && !profileImage && (
                  <img
                    src={defaultProfileIcon} // Default icon when no image is uploaded
                    alt="ProfileIcon"
                    className="profile-img"
                    style={{
                      cursor: "pointer",
                      width: "187px",
                      height: "187px",
                      objectFit: "cover",
                      borderRadius: "50%",
                    }}
                  />
                )}
                {profileImage && !loading && (
                  <img
                    src={profileImage}
                    alt="ProfileImg"
                    className="profile-img"
                    style={{
                      cursor: "pointer",
                      width: "187px",
                      height: "187px",
                      objectFit: "cover",
                      borderRadius: "50%",
                    }}
                  />
                )}
                {!loading && !profileImage && (
                  <img
                    src={editIcon}
                    alt="Edit Icon"
                    style={{
                      position: "absolute", // Keep edit icon fixed in the center
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)", // Center the edit icon
                      cursor: "pointer",
                    }}
                  />
                )}
                <input
                  type="file"
                  id="upload-image"
                  accept="image/*"
                  onChange={handleProfileImageChange}
                  style={{ display: "none" }}
                />
              </label>
            </Box>


                <Box
                  sx={accountStyle.editAccountInfoSection}
                >
                  <Box sx={accountStyle.editAccountinfoBox}>
                    <Box display="flex" justifyContent="space-evenly">
                      <label>
                        <strong>Role Type</strong>
                      </label>

                      <label>
                        <Field type="radio" name="roleType" value="SALES" />
                        Sales
                      </label>
                      <label>
                        <Field
                          type="radio"
                          name="roleType"
                          value="PRE_SALES"
                        />
                        Presales
                      </label>
                    </Box>
                    <ErrorMessage
                      name="roleType"
                      component="div"
                      className="error"
                    />
                  </Box>
                  <Box sx={accountStyle.editAccountinfoBox}>
                    <Field
                      as={Input}
                      type="text"
                      name="firstName"
                      placeholder="Enter first name"
                    />
                    <ErrorMessage
                      name="firstName"
                      component="div"
                      className="error"
                    />
                  </Box>
                  <Box sx={accountStyle.editAccountinfoBox}>
                    <Field
                      as={Input}
                      type="text"
                      name="lastName"
                      placeholder="Enter last name"
                    />
                    <ErrorMessage
                      name="lastName"
                      component="div"
                      className="error"
                    />
                  </Box>
                  <Box sx={accountStyle.editAccountinfoBox}>
                    <Field
                      as={Input}
                      type="email"
                      name="email"
                      placeholder="Enter email address"
                    />
                    <ErrorMessage
                      name="email"
                      component="div"
                      className="error"
                    />
                  </Box>
                  <Box sx={accountStyle.editAccountinfoBox}>
                    <Field
                      as={Input}
                      type="tel"
                      name="phoneNumber"
                      placeholder="Enter phone number"
                    />
                    <ErrorMessage
                      name="phoneNumber"
                      component="div"
                      className="error"
                    />
                  </Box>
                  <Box sx={accountStyle.editAccountinfoBox}>
                    <div className=" s-select">
                      <Select
                        id="state"
                        name="state"
                        options={stateOptions}
                        value={stateOptions.find(
                          (option) => option.label === values.state
                        )}
                        onChange={(selectedOption) => {
                          setFieldValue(
                            "state",
                            selectedOption ? selectedOption.label : ""
                          );
                          handleStateChange(selectedOption, setFieldValue);
                        }}
                        onBlur={handleBlur}
                        isClearable={true}
                        placeholder="Select State"
                      />
                      <ErrorMessage
                        name="state"
                        component="div"
                        className="error"
                      />
                    </div>
                  </Box>
                  <Box sx={accountStyle.editAccountinfoBox}>
                    <div className="s-select">
                      <Select
                        id="city"
                        name="city"
                        options={cityOptions}
                        value={cityOptions.find(
                          (option) => option.label === values.city
                        )}
                        onChange={(selectedOption) =>
                          setFieldValue(
                            "city",
                            selectedOption ? selectedOption.label : ""
                          )
                        }
                        onBlur={handleBlur}
                        isClearable={true}
                        placeholder="Select City"
                      />
                      <ErrorMessage
                        name="city"
                        component="div"
                        className="error"
                      />
                    </div>
                  </Box>
                  <Box sx={accountStyle.editAccountinfoBox}>
                    <Field
                      as={Textarea}
                      name="address"
                      placeholder="Address"
                    />
                    <ErrorMessage
                      name="address"
                      component="div"
                      className="error"
                    />
                  </Box>
                  <Box sx={accountStyle.editAccountinfoBox}>
                    <div className="s-select">
                      <Select
                        id="vp"
                        name="vp"
                        options={
                          values.roleType === "SALES"
                            ? vpOptions.filter(
                                (option) => option.role === "SALES"
                              )
                            : values.roleType === "PRE_SALES"
                            ? vpOptions.filter(
                                (option) => option.role === "PRE_SALES"
                              )
                            : []
                        }
                        value={vpOptions.find(
                          (option) => option.value === values.vp
                        )}
                        onChange={(selectedOption) =>
                          setFieldValue(
                            "vp",
                            selectedOption ? selectedOption.value : ""
                          )
                        }
                        onBlur={handleBlur}
                        isClearable={true}
                        getOptionLabel={(option) => option.label}
                        placeholder="Select VP"
                      />

                      <ErrorMessage
                        name="vp"
                        component="div"
                        className="error"
                      />
                    </div>
                  </Box>
                  {values.roleType === "PRE_SALES" && (
                    <Box sx={accountStyle.editAccountinfoBox}>
                      <Box marginBottom="0.5rem">
                        <label>
                          <strong>Property List</strong>
                        </label>
                      </Box>
                      <Box sx={accountStyle.editAccountinfoBox}>
                        <div className="s-select">
                          <Select
                            id="project"
                            name="project"
                            options={propertyOptions}
                            value={propertyOptions.find(
                              (option) => option.value === values.project
                            )}
                            onChange={(selectedOption) =>
                              setFieldValue(
                                "project",
                                selectedOption ? selectedOption.value : ""
                              )
                            }
                            onBlur={handleBlur}
                            isClearable={true}
                            placeholder="Select Property"
                          />
                          <ErrorMessage
                            name="project"
                            component="div"
                            className="error"
                          />
                          <IoIosAddCircle
                            onClick={handleAddProject}
                            style={{
                              fontSize: "28px",
                              color: "green",
                              marginTop: "5px",
                              cursor: "pointer",
                            }}
                          />
                        </div>
                      </Box>
                    </Box>
                  )}
                  {showAdditionalProjects &&
                    additionalProjects.map((_, index) => (
                      <Box key={index} sx={accountStyle.editAccountinfoBox}>
                        <div className="s-select">
                          <Select
                            id={`additionalProjects[${index}]`}
                            name={`additionalProjects[${index}]`}
                            options={propertyOptions}
                            value={propertyOptions.find(
                              (option) =>
                                option.value ===
                                additionalProjects[index].project
                            )}
                            onChange={(selectedOption) =>
                              handleProjectChange(
                                index,
                                selectedOption,
                                setFieldValue
                              )
                            }
                            onBlur={handleBlur}
                            isClearable={true}
                            placeholder="Select Additional Property"
                          />
                          <ErrorMessage
                            name={`additionalProjects[${index}]`}
                            component="div"
                            className="error"
                          />
                          <IoIosRemoveCircle
                            onClick={() => handleRemoveProject(index)}
                            style={{
                              fontSize: "28px",
                              color: "red",
                              cursor: "pointer",
                              marginTop: "5px",
                            }}
                          />
                        </div>
                      </Box>
                    ))}
                  <Box
                    mb={2}
                    width="100%"
                    display="flex"
                    justifyContent="center"
                  >
                    <ButtonComponent
                      type="submit"
                      name={"Add Member"}
                      disabled={isSubmitting}
                      height="43px"
                      fullWidth
                    />
                  </Box>
                </Box>
              </Form>
            )}
          </Formik>
</Box>

           

          </Paper>
   
      </Box>
   
    </MainLayout>
  );
};

export default AddManager;
