import React, { useState } from "react";
import SearchBar from "../SearchBar";
import { Grid, styled, Box } from "@mui/material";
import ButtonComponent from "../Button";
import BasicDatePicker from "../DatePicker";

const StyledBox = styled(Box)(({ theme }) => ({
  padding: "10px",
  borderRadius: "15px",
  backgroundColor: "rgba(0, 176, 80, 0.05)",
  "& .displayEnd": {
    display: "flex",
    justifyContent: "flex-end",
    backgroundColor: "rgba(0, 0, 0, 0.02)",
    paddingTop: "10px",
    columnGap: "8px",
    [theme.breakpoints.down("md")]: {
      columnGap: "0px",
    },
  },
  "& .MuiInputBase-root": {
    color: "#0B1426 !important",
    height: "44px",
    backgroundColor: "transparent",
  },
  "& .MuiOutlinedInput-input": {
    color: "#0B1426",
  },
}));

const Filter = ({
  searchPlaceholder,
  onSearch,
  onFromDateChange,
  onToDateChange,
}) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);

  const handleSearch = (event, value) => {
    setSearchQuery(value);
    onSearch(value);
  };

  const handleFromDateChange = (date) => {
    setFromDate(date);
    onFromDateChange(date);
  };

  const handleToDateChange = (date) => {
    setToDate(date);
    onToDateChange(date);
  };

  const handleClear = () => {
    setSearchQuery("");
    setFromDate(null);
    setToDate(null);

    onSearch("");
    onFromDateChange(null);
    onToDateChange(null);
  };

  return (
    <StyledBox>
      <Grid container spacing={3} alignItems="flex-end">
        <Grid item xs={12} sm={6} md={4}>
          <SearchBar
            placeholder={searchPlaceholder}
            onSearch={handleSearch}
            value={searchQuery}
            options={[]}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={2}>
          <BasicDatePicker
            label="From Date"
            onDateChange={handleFromDateChange}
            value={fromDate}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={2}>
          <BasicDatePicker
            label="To Date"
            onDateChange={handleToDateChange}
            value={toDate}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={2}>
          <ButtonComponent
            name="Reset Filter"
            borderRadius="100px"
            backgroundColor="rgba(0, 0, 0, 0.05)"
            color="black"
            onClick={handleClear}
            width={"100%"}
            margin={"0px"}
            height={"45px"}
          />
        </Grid>
      </Grid>
    </StyledBox>
  );
};

export default Filter;
