import React from "react";
import { useFormik } from "formik";
import MainLayout from "../../layout/MainLayout";
import { Grid, Paper, Typography, Box, Divider } from "@mui/material";
import ButtonComponent from "../../component/Button";
import { Link, useNavigate } from "react-router-dom";
import { postAPIHandler } from "../../config/service";
import { changePasswordSchema } from "../../schemas";
import InputField from "../../component/InputField";
import { toast } from "react-hot-toast";
import typographyStyles from "../../theme/Typography";
import arrowBack from "../../assets/arrowBack.svg";

const changePasswordStyle = {
  changePasswordContainer: {
    marginTop: "20px",
    display: "flex",
    flexDirection: "column",
  },
  accountTitle: {
    ...typographyStyles.h4,
    fontWeight: "600",
    padding: "1%",
  },
  changePasswordTitle: {
    ...typographyStyles.h4,
  },
  contentSection: {
    padding: "20px 25%",
    display: "flex",
    flexDirection: "column",
    gap: "20px",
  },
  inputBox: {
    display: "flex",
    flexDirection: "column",
    gap: "4px",
  },
  changePasswordLabel: {
    ...typographyStyles.h6,
  },
  btnBox: {
    display: "flex",
    justifyContent: "center",
  },
};

const ChangePassword = () => {
  const navigate = useNavigate();
  // const location = useLocation();
  // const oldPass = location.state || "";

  const initialValues = {
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  };

  const { values, errors, handleBlur, handleChange, touched, handleSubmit } =
    useFormik({
      initialValues: initialValues,
      validationSchema: changePasswordSchema,
      onSubmit: (values) => {
        changePassword(values);
      },
    });

  const changePassword = async (values) => {
    try {
      const response = await postAPIHandler({
        endPoint: "changePassword",
        dataToSend: {
          currentPassword: values.oldPassword,
          newPassword: values.newPassword,
          confirmPassword: values.confirmPassword,
        },
      });

      console.log(response.data.result);
      if (response && response.data.responseCode === 200) {
        toast.success("Password updated successfully");
        navigate("/myaccount");
      }else{
        toast.error("Failed to update password, Please try again later. ")
      }
    } catch (error) {
      console.error("Error:", error);
      
      
    }
  };

  return (
    <MainLayout>
      <Grid style={{ padding: "1rem" }}>
        <Grid
          style={{
            display: "flex",

            justifyContent: "start",
            alignItems: "center",
          }}
        >
          <Typography style={{ fontWeight: 700, fontSize: "30px" }}>
            My Account
          </Typography>
        </Grid>

        <Paper elevation={0}>
          <Box style={{ display: "flex", padding: "1rem" }}>
            <Link to={"/myaccount"}>
              <img
                src={arrowBack}
                alt="arroWback"
                style={{
                  width: "2.813rem",
                  height: "2.813rem",
                  cursor: "pointer",
                }}
              />
            </Link>
            <Box sx={changePasswordStyle.accountTitle}>Change Password</Box>
          </Box>

          <Box sx={changePasswordStyle.changePasswordContainer}>
            <form
              style={changePasswordStyle.contentSection}
              onSubmit={handleSubmit}
            >
              <Box sx={changePasswordStyle.inputBox}>
                <Typography sx={changePasswordStyle.changePasswordLabel}>
                  Old Password
                </Typography>
                <InputField
                  className="authTextField"
                  type="password"
                  name="oldPassword"
                  value={values.oldPassword}
                  maxLength={16}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  restrictCopypaste={true}
                  placeholder="Enter the old password"
                />
                {errors.oldPassword && touched.oldPassword ? (
                  <p style={{ color: "red", marginTop: "2px", float: "left" }}>
                    {errors.oldPassword}
                  </p>
                ) : null}
              </Box>
              <Box sx={changePasswordStyle.inputBox}>
                <Typography sx={changePasswordStyle.changePasswordLabel}>
                  New Password
                </Typography>
                <InputField
                  className="authTextField"
                  type="password"
                  name="newPassword"
                  value={values.newPassword}
                  maxLength={16}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  restrictCopypaste={true}
                  placeholder="Enter the new password"
                />
                {errors.newPassword && touched.newPassword ? (
                  <p style={{ color: "red", marginTop: "2px", float: "left" }}>
                    {errors.newPassword}
                  </p>
                ) : null}
              </Box>
              <Box sx={changePasswordStyle.inputBox}>
                <Typography sx={changePasswordStyle.changePasswordLabel}>
                  Confirm Password
                </Typography>
                <InputField
                  className="authTextField"
                  type="password"
                  name="confirmPassword"
                  value={values.confirmPassword}
                  maxLength={16}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  restrictCopypaste={true}
                  placeholder="Enter the confirm password"
                />
                {errors.confirmPassword && touched.confirmPassword ? (
                  <p style={{ color: "red", marginTop: "2px", float: "left" }}>
                    {errors.confirmPassword}
                  </p>
                ) : null}
              </Box>
              <Box sx={changePasswordStyle.btnBox}>
                <ButtonComponent name={"Change Password"} type="submit" />
              </Box>
            </form>
          </Box>
        </Paper>
      </Grid>
    </MainLayout>
  );
};

export default ChangePassword;
