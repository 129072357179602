import React, { useEffect, useState } from "react";
import MainLayout from "../../layout/MainLayout";
import { Grid, Typography, Paper } from "@mui/material";
import LeadFilter from "../../component/Filters/LeadFilter";
import CustomizedTables from "../../component/Table/LeadsManagementData";

import { getAPIHandler } from "../../config/service";

const LeadsManagement = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [status, setStatus] = useState("");


  const handleSearch = (query) => {
    setSearchQuery(query);
  };

  const handleFromDateChange = (date) => {
    setFromDate(date);
  };

  const handleToDateChange = (date) => {
    setToDate(date);
  };

  const handleStatusSelect = (status) => {
    setStatus(status);
  };

  return (
    <MainLayout>
      <Grid>

        <Grid style={{ marginBottom: "15px", marginLeft: "15px" }}>
          <Paper elevation={0}>
            <Grid style={{ marginBottom: "4px", padding: "15px" }}>
              <LeadFilter
                searchPlaceholder={"Search by clientName"}
                onSearch={handleSearch}
                onFromDateChange={handleFromDateChange}
                onToDateChange={handleToDateChange}
                onStatusChange={handleStatusSelect}
              />
            </Grid>

            <Grid style={{ padding: "15px" }}>
              <CustomizedTables searchQuery={searchQuery}
              fromDate={fromDate}
              toDate={toDate} leadStatus={status}/>
              
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </MainLayout>
  );
};

export default LeadsManagement;
