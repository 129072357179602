import React, { useState } from "react";
import MainLayout from "../../../layout/MainLayout";
import { Paper, TextField, Typography, Grid } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import ButtonComponent from "../../../component/Button";
import { Formik, Form, Field, ErrorMessage } from "formik";
import arrowBack from "../../../assets/arrowBack.svg";
import "./AddDeveloper.css";
import { postAPIHandler } from "../../../config/service";
import { developerSchema } from "../../../schemas";
import { toast } from "react-hot-toast";
import BubbleLoader from "../../../component/Loader/BubbleLoader";

const initialValues = {
  developerName: "",
};

const AddDeveloper = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const onSubmit = async (values, { setSubmitting }) => {
    setLoading(true)
    try {
      const response = await postAPIHandler({
        endPoint: "addDeveloper",
        dataToSend: {
          fullName: values.developerName,
        },
      });

      console.log(response.data);
      if (response && response.data.responseCode === 200) {
        toast.success("Developer added successfully.");
        navigate("/developermanagement");
      }else{
        toast.error("Developer alredy exists.")
      }

    } catch (error) {
      console.error("Error:", error);
      setSubmitting(false);
    }
    setLoading(false)
  };

  return (
    <MainLayout>
      <Grid>
        <Grid
          style={{
            display: "flex",

            alignItems: "center",
            padding: "1rem",
          }}
        >
          <Typography style={{ fontWeight: 700, fontSize: "30px" }}>
            Developer Management
          </Typography>
        </Grid>
        <Grid style={{ marginLeft: "15px", marginBottom: "15px" }}>
          <Paper elevation={0} style={{ padding: "15px" }}>
            <div>
              <div className="arrow">
                <Link to={"/developermanagement"}>
                  <img
                    src={arrowBack}
                    alt="arroWback"
                    style={{
                      width: "2.813rem",
                      height: "2.813rem",
                      cursor: "pointer",
                    }}
                  />
                </Link>
                <Typography
                variant="h5"
                sx={{ marginLeft: "1rem", fontWeight: 800 }}
              >
                Add Developer
              </Typography>
              </div>

              <div className="content-container">
                <Formik
                  initialValues={initialValues}
                  validationSchema={developerSchema}
                  onSubmit={onSubmit}
                >
                  {() => (
                    <Form>
                      <div>
                        <div className="developer-title">
                          <label htmlFor="developerName">
                            <strong>
                              Developer Name{" "}
                              <span style={{ color: "red" }}>*</span> :{" "}
                            </strong>
                          </label>
                          <Field
                            as={TextField}
                            type="text"
                            name="developerName"
                            variant="outlined"
                            className="form-control"
                            placeholder="Enter developer name"
                            onKeyPress={(e) => {
                              if (e.target.value.length === 0) {
                                if (e.key === " ") e.preventDefault();
                              }
                              if (
                                e.target.value.length >= 60 &&
                                e.key !== "Backspace"
                              ) {
                                e.preventDefault();
                              }
                            }}
                          />
                        </div>

                        <ErrorMessage
                          name="developerName"
                          component="div"
                          className="errorr"
                        />
                      </div>

                      <div className="button-container">
                      <ButtonComponent
                      height="43px"
                      disabled={loading}
                    >
                      {loading ? <BubbleLoader color="#FFF" size="0.7rem" /> : "Add Developer"} 
                    </ButtonComponent>
                    
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </Paper>
        </Grid>
      </Grid>
    </MainLayout>
  );
};

export default AddDeveloper;
