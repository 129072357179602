import React, { useState } from "react";
import SearchBar from "../SearchBar";
import { Grid, styled, Box } from "@mui/material";
import ButtonComponent from "../Button";
import BasicDatePicker from "../DatePicker";
import Dropdown from "../FilterDropdown";

const StyledBox = styled(Box)(({ theme }) => ({
  padding: "10px",
  borderRadius: "15px",
  backgroundColor: "rgba(0, 176, 80, 0.05)",
  "& .displayEnd": {
    display: "flex",
    justifyContent: "flex-end",
    backgroundColor: "rgba(0, 0, 0, 0.02)",
    paddingTop: "10px",
    columnGap: "8px",
    [theme.breakpoints.down("md")]: {
      columnGap: "0px",
    },
  },
  "& .MuiInputBase-root": {
    color: "#0B1426 !important",
    height: "44px",
    backgroundColor: "transparent",
  },
  "& .MuiOutlinedInput-input": {
    color: "#0B1426",
  },
}));

const Filter = ({
  searchPlaceholder,
  onSearch,
  onFromDateChange,
  onToDateChange,
  onStatusChange,
}) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [status, setStatus] = useState("");

  const handleSearch = (event, value) => {
    setSearchQuery(value); // Update the searchQuery state
    onSearch(value);
  };

  const handleFromDateChange = (date) => {
    setFromDate(date); // Update the fromDate state
    onFromDateChange(date);
  };

  const handleToDateChange = (date) => {
    setToDate(date); // Update the toDate state
    onToDateChange(date);
  };

  const handleStatusChange = (value) => {
    setStatus(value);
    onStatusChange(value);
  };

  const handleClear = () => {
    setSearchQuery(""); // Clear search query
    setFromDate(null); // Clear fromDate
    setToDate(null); // Clear toDate
    setStatus(""); // Reset category

    onSearch(""); // Trigger onSearch with an empty query
    onFromDateChange(null); // Trigger onFromDateChange with null
    onToDateChange(null); // Trigger onToDateChange with null
    onStatusChange("");
  };

  return (
    <StyledBox>
      <Grid container spacing={3} alignItems="flex-end">
        <Grid item xs={12} sm={6} md={4}>
          <SearchBar
            placeholder={searchPlaceholder}
            onSearch={handleSearch}
            value={searchQuery} // Bind searchQuery state to the value prop
            options={[]}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={2}>
          <BasicDatePicker
            label="From Date"
            onDateChange={handleFromDateChange}
            value={fromDate} // Bind fromDate state to the value prop
          />
        </Grid>
        <Grid item xs={12} sm={6} md={2}>
          <BasicDatePicker
            label="To Date"
            onDateChange={handleToDateChange}
            value={toDate} // Bind toDate state to the value prop
          />
        </Grid>

        <Grid item xs={12} sm={6} md={2}>
          <ButtonComponent
            name="Reset Filter"
            borderRadius="100px"
            backgroundColor="rgba(0, 0, 0, 0.05)"
            color="black"
            onClick={handleClear}
            width={"100%"}
            margin={"0px"}
            height={"45px"}
          />
        </Grid>
      </Grid>
    </StyledBox>
  );
};

export default Filter;

// <Dropdown
//   options={["Resent Request", "Generated Lead", "Booked Visit"]}
//   onChange={handleStatusChange}
//   value={status}
//   placeholder="Lead Status"
// />
