import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import MainLayout from "../../../layout/MainLayout";
import { Paper, Box, Typography } from "@mui/material";
import arrowBack from "../../../assets/arrowBack.svg";
import Input from "@mui/joy/Input";
import Select from "react-select";
import Textarea from "@mui/joy/Textarea";
import ButtonComponent from "../../../component/Button";
import { Link, useNavigate } from "react-router-dom";
import { postAPIHandler } from "../../../config/service";
import { broadcastSchema } from "../../../schemas";
import toast from "react-hot-toast";

const AddBroadcast = () => {
  const navigate = useNavigate();

  const userTypeOptions = [
    { value: "VP", label: "Vice President" },
    { value: "MANAGER", label: "Manager" },
    { value: "EXECUTIVE", label: "Executive" },
  ];

  const onSubmit = async (values, { setSubmitting }) => {
    try {
      const response = await postAPIHandler({
        endPoint: "addBroadcast",
        dataToSend: {
          title: values.title,
          broadcastMessage: values.broadcastMessage,
          userType: values.userType,
        },
      });
      console.log(response.data);
      if (response && response.data.responseCode === 200) {
        toast.success("Broadcast added successfully.");
        navigate("/broadcastmanagement");
      } else {
        toast.error("Error in adding broadcast.");
      }
    } catch (error) {
      console.error("Error:", error);
      setSubmitting(false);
    }
  };

  return (
    <MainLayout>
      <Box display="flex" flexDirection="column">

        <Box display="flex" alignItems="center" padding="1rem">
          <Typography variant="h4" fontWeight={700}>
            Broadcast Management
          </Typography>
        </Box>

        <Box sx={{ marginLeft: "15px", marginBottom: "15px" }}>
          <Paper elevation={0} sx={{ padding: "15px", overflowY: "auto",
              scrollbarWidth: "none" }}>
              
            <Box display="flex" alignItems="center" mb={2}>
              <Link to="/broadcastmanagement">
                <img
                  src={arrowBack}
                  alt="arrowBack"
                  style={{
                    width: "2.813rem",
                    height: "2.813rem",
                    cursor: "pointer",
                  }}
                />
              </Link>
              <Typography variant="h5" sx={{ marginLeft: "1rem", fontWeight: 800 }}>
                Add Broadcast
              </Typography>
            </Box>

            <Formik
              initialValues={{
                title: "",
                broadcastMessage: "",
                userType: "",
              }}
              validationSchema={broadcastSchema}
              onSubmit={onSubmit}
            >
              {({ isSubmitting, handleBlur, setFieldValue, values }) => (
                <Form>
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center" 
                    justifyContent="center" 
                    spacing={2}
                  >
                   
                    <Box mb={2} width="100%" maxWidth="500px">
                      <Field
                        as={Input}
                        type="text"
                        name="title"
                        placeholder="Enter Title"
                        fullWidth
                      />
                      <ErrorMessage
                        name="title"
                        component="div"
                        className="error"
                      />
                    </Box>

            
                    <Box mb={2} width="100%" maxWidth="500px">
                      <Field
                        as={Textarea}
                        minRows={4}
                        name="broadcastMessage"
                        placeholder="Enter Broadcast Message"
                        fullWidth
                      />
                      <ErrorMessage
                        name="broadcastMessage"
                        component="div"
                        className="error"
                      />
                    </Box>

                    <Box mb={2} width="100%" maxWidth="500px">
                      <Select
                        name="userType"
                        options={userTypeOptions}
                        value={userTypeOptions.find(
                          (option) => option.value === values.userType
                        )}
                        onChange={(selectedOption) =>
                          setFieldValue(
                            "userType",
                            selectedOption ? selectedOption.value : ""
                          )
                        }
                        onBlur={handleBlur}
                        isClearable
                        placeholder="Select User Type"
                        fullWidth
                      />
                      <ErrorMessage
                        name="userType"
                        component="div"
                        className="error"
                      />
                    </Box>

                    <Box
                      mb={2}
                      width="100%"
                      display="flex"
                      justifyContent="center"
                    >
                      <ButtonComponent
                        type="submit"
                        name="Add Broadcast"
                        disabled={isSubmitting}
                        height="43px"
                        fullWidth
                      />
                    </Box>
                  </Box>
                </Form>
              )}
            </Formik>

            
          </Paper>
        </Box>
      </Box>
    </MainLayout>
  );
};

export default AddBroadcast;
