import React from "react";
import logo from "../assets/Logo.png";
import { Grid, Paper, Typography } from "@mui/material";

const Header = ({ name }) => {
  const headerContentStyle = {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "1rem",
    gap: "13.5rem",
  };
  const headingStyle = { fontSize: "20px", fontWeight: 700 };

  const paperStyle = {
    height: "100%",
  };

  return (
    <Paper elevation={0} style={paperStyle}>
      <Grid style={headerContentStyle}>
        <img src={logo} alt="logo" />
        <Typography style={headingStyle}>Dashboard, Welcome {name}</Typography>
      </Grid>
    </Paper>
  );
};

export default Header;
