import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import ButtonComponent from "../Button";
import { SiResend } from "react-icons/si";
import Dropdown from "../dropdown/Dropdown";
import { getAPIHandler, putAPIHandler } from "../../config/service";
import toast from "react-hot-toast";

export default function MaxWidthDialog({generatedLeadId}) {
  const [open, setOpen] = React.useState(false);
  const [executives, setExecutives] = React.useState([]);
  const [selectedExecutive, setSelectedExecutive] = React.useState("");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSelect = (selectedOption) => {
    const selectedExec = executives.find(exec => exec.label === selectedOption);
    setSelectedExecutive(selectedExec ? selectedExec.value : "");
  };

  const fetchUserData = async () => {
    try {
      const response = await getAPIHandler({
        endPoint: "listUserDetailsByType",
      });
      if (
        response.data &&
        response.data.result &&
        response.data.result.userDetails
      ) {
        const users = response.data.result.userDetails;
        const salesExecutives = users.filter(
          (user) =>
            user.userType === "EXECUTIVE" &&  (user.userRoleType === "PRE_SALES" || user.userRoleType === "BOTH")
        );
        const executiveOptions = salesExecutives
        .map(
          (executive) => ({
            label: executive.fullName,
            value: executive._id 
          })
        
        ).sort((a, b) => a.label.localeCompare(b.label));

        setExecutives(executiveOptions);
      } else {
        console.error("No user data received.");
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  const reassignExecutive = async () => {
    if (!selectedExecutive || !generatedLeadId) {
      console.error("Missing data for reassignment.");
      return;
    }

    try {
      const response = await putAPIHandler({
        endPoint: "reassignPreSalesExecutive",
        dataToSend: {
          generatedLeadId: generatedLeadId,
          executiveId: selectedExecutive, 
        },
      });

      if (response && response.data) {
        console.log("Reassigned successfully", response.data);
        toast.success("Executive re-assigned")
        handleClose(); 
      } else {
        console.error("Failed to reassign.");
        toast.error("Failed to reassign.")
      }
    } catch (error) {
      console.error("Error during reassignment:", error);
    }
  };

  React.useEffect(() => {
    fetchUserData();
  }, []);

  return (
    <React.Fragment>
      <SiResend onClick={handleClickOpen} />

      <Dialog
        maxWidth="xl"
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: {},
        }}
      >
        <DialogTitle style={{ display: "flex", justifyContent: "center" }}>
          Re assign Executive Member
        </DialogTitle>

        <DialogContent>
          <DialogContentText>
            <Dropdown
            options={executives.map(exec => exec.label)}
              onSelect={handleSelect}
              width={"289px"}
              border={"none"}
              color={"#ccc"}
            />
          </DialogContentText>
        </DialogContent>

        <DialogActions style={{ display: "flex", justifyContent: "center" }}>
          <ButtonComponent
            name={"Assign"}
            onClick={reassignExecutive}
            width={"10rem"}
            borderRadius={"100px"}
          />
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
