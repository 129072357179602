import React, { useState } from "react";
import MainLayout from "../../layout/MainLayout";
import CustomizedTables from "../../component/Table/ManagerManagementData";
import { Grid, Typography, Paper } from "@mui/material";
import Filter from "../../component/Filters/Filter";
import ButtonComponent from "../../component/Button";
import { useNavigate } from "react-router-dom";

const ManagerManagement = () => {
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState('');
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);

  const handleSearch = (query) => {
    setSearchQuery(query);
  };


  const handleFromDateChange = (date) => {
    setFromDate(date);
  };

  const handleToDateChange = (date) => {
    setToDate(date);
  };

  const handleDownloadCSV = () => {
    const downloadFunction = document.getElementById("manager-tablesdata-download");
    if (downloadFunction) {
      downloadFunction.click();
    }
  };

  let downloadCSVFunction = null;

  return (
    <MainLayout>
      <Grid>
        <Grid
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            marginLeft: "20px",
          }}
        >
          <Typography style={{ fontWeight: 700, fontSize: "30px" }}>
            Manager Management
          </Typography>
          <Grid>
            <ButtonComponent
              name={"Download CSV"}
              borderRadius={"100px"}
              backgroundColor={"rgba(0, 0, 0, 0.05)"}
              onClick={handleDownloadCSV}
              color={"black"}

            />
            <ButtonComponent
              name={"+Add Manager"}
              borderRadius={"100px"}
              onClick={() => navigate("/managermanagement/addmanager")}
            />
          </Grid>
        </Grid>
        <Grid style={{ marginLeft:"15px", marginBottom:"15px" }}>
          <Paper elevation={0} >
            <Grid style={{ marginBottom: "4px", padding: "15px" }}>
              <Filter searchPlaceholder={"Search by manager name"} onSearch={handleSearch}
              onFromDateChange={handleFromDateChange}
              onToDateChange={handleToDateChange} />
            </Grid>

            <Grid style={{ padding: "15px" }}>
              <CustomizedTables searchQuery={searchQuery}
              fromDate={fromDate}
              toDate={toDate}
              setDownloadCSVFunction={(func) => {
                downloadCSVFunction = func;
              }}/>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </MainLayout>
  );
};

export default ManagerManagement;
