import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Grid, Pagination, Tooltip } from "@mui/material";
import { useNavigate } from "react-router-dom";
import LogoutPopup from "../../component/Popup/Logoutpopup";
import { IoEyeOutline } from "react-icons/io5";
import { BiEditAlt } from "react-icons/bi";
import { BiBlock } from "react-icons/bi";
import { getAPIHandler } from "../../config/service";
import { ApiConfig } from "../../config/ApiConfig";
import { format } from "date-fns";
import DataNotFound from "../DataNotFound";
import { saveAs } from "file-saver";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "rgb(0, 176, 80)",
    color: theme.palette.common.white,
    fontSize: "1rem",
    fontWeight: 400,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    fontWeight: 500,
  },
}));

const StyledTableRow = styled(TableRow)(() => ({
  "&:nth-of-type(even)": {
    backgroundColor: "rgba(0, 176, 80, 0.05)",
  },
  "&:last-child td, &:last-child th": {},
  "& td ": {},
}));
export default function CustomizedTables(props) {
  const {
    userId,
    searchQuery,
    fromDate,
    toDate,
    category,
    constructionStatus,
  } = props;

  const [data, setData] = React.useState([]);
  const [totalPages, setTotalPages] = React.useState(0);

  const [filter, setFilter] = React.useState({
    page: 1,
    limit: 10,
  });
  const navigate = useNavigate();

  const handleChangePage = (event, value) => {
    setFilter({ ...filter, page: value });
  };

  const handleViewClick = (propertyId) => {
    navigate(`/propertymanagement/viewassignedproperty/${propertyId}`);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);

    if (date.toDateString() === today.toDateString()) {
      return "Today";
    } else if (date.toDateString() === tomorrow.toDateString()) {
      return "Tomorrow";
    } else {
      return date.toLocaleDateString("en-US");
    }
  };

  React.useEffect(() => {
    getData();
  }, [filter, searchQuery, fromDate, toDate, category, constructionStatus]);

  const getData = async () => {
    try {
      const formattedFromDate = fromDate
        ? format(new Date(fromDate), "yyyy-MM-dd")
        : undefined;
      const formattedToDate = toDate
        ? format(new Date(toDate), "yyyy-MM-dd")
        : undefined;
      const paramsData = {
        ...filter,
        userId: userId,
        name: searchQuery || undefined,
        fromDate: formattedFromDate,
        toDate: formattedToDate,
        category: category || undefined,
        constructionStatus: constructionStatus || undefined,
      };

      const response = await getAPIHandler({
        endPoint: "getAllUserProperty",
        paramsData,
      });

      console.log("Response:", response.data);
      setData(response.data.result.property);
      setTotalPages(response.data.result.totalPages || 0);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const renderCellWithTooltip = (content, limit) => {
    if (content.length > limit) {
      return (
        <Tooltip title={content}>
          <span>{`${content.substring(0, limit)}...`}</span>
        </Tooltip>
      );
    }
    return content;
  };

  const createRowsFromPropertyManagementData = (data) => {
    return data.map((management, index) => {
      return {
        index: index + 1,
        _id: management._id,
        propertyName: management.propertyName,
        createdAt: new Date(management.createdAt).toLocaleString("en-IN", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
          timeZone: "Asia/Kolkata",
        }),
        category: management.category,
        updatedAt: formatDate(management.updatedAt),
        constructionStatus: management.constructionStatus,
        status: management.status,
        memberType: management.memberType,
      };
    });
  };

  const rows =
    data.length > 0 ? createRowsFromPropertyManagementData(data) : [];

  return (
    <div>
      {data.length < 1 ? (
        <DataNotFound />
      ) : (
        <TableContainer
          component={Paper}
          sx={{ borderRadius: "15px", boxShadow: "0 0 0px rgba(0, 0, 0, 0.1)" }}
        >
          <Table aria-label="customized table">
            <TableHead>
              <TableRow
                sx={{
                  "& .MuiTableCell-root": {
                    padding: "10px",
                  },
                }}
              >
                <StyledTableCell align="left">S.No.</StyledTableCell>
                <StyledTableCell align="left">Property Name</StyledTableCell>
                <StyledTableCell align="left">Listed Date</StyledTableCell>
                <StyledTableCell align="left">Type</StyledTableCell>
                <StyledTableCell align="left">
                  Possession Status
                </StyledTableCell>
                <StyledTableCell align="left">
                  Construction Status
                </StyledTableCell>

                <StyledTableCell align="left">Status</StyledTableCell>
                <StyledTableCell align="left">Added By</StyledTableCell>
                <StyledTableCell align="left">Action</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody
              sx={{
                "& .MuiTableCell-root": {
                  padding: "11px",
                },
              }}
            >
              {rows.map((row, index) => (
                <StyledTableRow key={index}>
                  <StyledTableCell align="left">{row.index}</StyledTableCell>
                  <StyledTableCell align="left">
                    {renderCellWithTooltip(row.propertyName, 20)}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {row.createdAt}
                  </StyledTableCell>
                  <StyledTableCell align="left">{row.category}</StyledTableCell>
                  <StyledTableCell align="left">
                    {row.updatedAt}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {row.constructionStatus}
                  </StyledTableCell>
                  <StyledTableCell
                    align="left"
                    style={{ color: row.status === "ACTIVE" ? "green" : "red" }}
                  >
                    {row.status}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {row.memberType}
                  </StyledTableCell>
                  <StyledTableCell>
                    <Grid
                      style={{
                        display: "flex",
                        gap: "0.7rem",
                        alignItems: "center",
                      }}
                    >
                      <IoEyeOutline
                        onClick={() => handleViewClick(row._id)}
                        style={{ fontSize: "24px" }}
                      />
                    </Grid>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {totalPages > 1 && (
        <Grid container justifyContent="center" mt={3}>
          <Pagination
            color="success"
            count={totalPages}
            page={filter.page}
            onChange={handleChangePage}
          />
        </Grid>
      )}
    </div>
  );
}
