import React, {useState, useEffect} from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { Typography, Grid, Paper, Divider } from "@mui/material";
import Box from "@mui/material/Box";
import MainLayout from "../../layout/MainLayout";
import CustomizedTable from "../../component/Table/RequestedLeadMangemetData";
import CustomizedTables from "../../component/Table/LeadsManagementData";
import LeadFilter from "../../component/Filters/Filter";
import ButtonComponent from "../../component/Button";
import { getAPIHandler, postAPIHandlerForFile } from "../../config/service";
import LeadAnalysisCard from "../../component/LeadCard/LeadCard";
import AssignedProperty from "../../assets/AssignedProperty.png";
import RequestedLeads from "../../assets/RequestedLeads.png";
import GeneratedLeads from "../../assets/GeneratedLeads.png";
import toast from "react-hot-toast";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <Box sx={{ padding: "0px 10px" }} {...other}>
      {value === index && <Box>{children}</Box>}
    </Box>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export default function MainLeadManagement() {
  const [value, setValue] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [totalRequests, setTotalRequests] = useState(0);
  const [totalGeneratedLeads, setTotalGeneratedLeads] = useState(0);
  const [totalBookedVisits, setTotalBookedVisits] = useState(0);
  const [leads, setLeads] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchLeads = async () => {
      try {
        const response = await getAPIHandler({
          endPoint: "getAllLeads",
        });
        if (response.data && response.data.result && response.data.result.allLeads) {
          const leadsData = response.data.result.allLeads;
          setLeads(leadsData);
          calculateLeadCounts(leadsData);
        } else {
          console.error("Unexpected response structure:", response.data);
        }
      } catch (error) {
        console.error("Error fetching leads:", error);
      }
    };

    fetchLeads();
  }, []);

  const calculateLeadCounts = (leadsData) => {
    const requests = leadsData.length;
    const generatedLeads = leadsData.filter(
      (lead) => lead.leadStatus === "GENERATEDLEAD"
    ).length;
    const bookedVisits = leadsData.filter(
      (lead) => lead.leadStatus === "BOOKEDVISIT"
    ).length;

    setTotalRequests(requests);
    setTotalGeneratedLeads(generatedLeads);
    setTotalBookedVisits(bookedVisits);
  };

  const handleSearch = (query) => {
    setSearchQuery(query);
  };

  const handleFromDateChange = (date) => {
    setFromDate(date);
  };

  const handleToDateChange = (date) => {
    setToDate(date);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const triggerFileInput = () => {
    document.getElementById("bulk-upload").click();
  };

  const handleFileChange = async (event) => {
    const selectedFile = event.target.files[0];
    if (!selectedFile) {
      toast.error("Please select a file to upload.");
      return;
    }
    
    const formData = new FormData();
    formData.append("file", selectedFile);
  
    setIsLoading(true);
  
    try {
      console.log("Uploading file:", selectedFile.name); // Log the file name
      const response = await postAPIHandlerForFile({
        endPoint: "markLeadRequestUsingFile",
        dataToSend: formData,
      });
  
      console.log("Response from API:", response); // Log the API response
  
      if (response && response.status === 200) {
        toast.success("Lead requests added.");
      } else {
        toast.error(response?.data?.message || "File upload failed.");
      }
    } catch (error) {
      console.error("Error during file upload:", error);
      toast.error("An error occurred while uploading the file.");
    } finally {
      setIsLoading(false);
    }
  };
  
  
  
  
  return (
    <MainLayout>
      <Grid
        container
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          ml: 2,
        }}
      >
        <Typography sx={{ fontWeight: 700, fontSize: "30px" }}>
          Lead Management
        </Typography>

        {value === 0 && (
          <Grid>
          <input
              type="file"
              accept=".xlsx"
              onChange={handleFileChange} 
              style={{ display: "none" }}
              id="bulk-upload"
            />
            <ButtonComponent
              name={"Upload in bulk"}
              borderRadius={"100px"}
              onClick={triggerFileInput}
              disabled={isLoading}
            />
          </Grid>
        )}
        {value === 1 && (
          <Grid style={{ display: "flex", flexDirection: "row", gap: "2rem" }}>
            <LeadAnalysisCard
              backgroundImage={GeneratedLeads}
              heading="Total Generated Lead"
              count={totalGeneratedLeads}
              width="209px"
              height="79px"
            />
            <LeadAnalysisCard
              backgroundImage={RequestedLeads}
              heading="Total Booked Visit"
              count={totalBookedVisits}
              width="209px"
              height="79px"
            />
          </Grid>
        )}
      </Grid>

      <Grid sx={{ overflowY: "auto" }} paddingX={2}>
        <Paper elevation={0}>
          <Grid container spacing={2} padding={1}>
            <Grid item xs={2}></Grid>

            <Grid item xs={8}>
              <Box display="flex" justifyContent="center">
                <Tabs value={value} onChange={handleChange} centered>
                  {["Requested Lead", "Generated Lead"].map((label, index) => (
                    <Tab
                      key={index}
                      label={
                        <Typography
                          sx={{
                            textTransform: "none",
                            color: value === index ? "rgba(0, 176, 80, 1)" : "#333",
                          }}
                        >
                          {label}
                        </Typography>
                      }
                    />
                  ))}
                </Tabs>
              </Box>
            </Grid>
          </Grid>

          <Divider sx={{ margin: 1 }} />

          <CustomTabPanel value={value} index={0}>
            <Grid>
              <CustomizedTable
                searchQuery={searchQuery}
                fromDate={fromDate}
                toDate={toDate}
              />
            </Grid>
          </CustomTabPanel>

          <CustomTabPanel value={value} index={1}>
            <Grid>
              <Box sx={{ marginBottom: "15px" }}>
                <LeadFilter
                  searchPlaceholder={"Search by property name"}
                  onSearch={handleSearch}
                  onFromDateChange={handleFromDateChange}
                  onToDateChange={handleToDateChange}
                />
              </Box>
              <CustomizedTables
                searchQuery={searchQuery}
                fromDate={fromDate}
                toDate={toDate}
              />
            </Grid>
          </CustomTabPanel>
        </Paper>
      </Grid>
    </MainLayout>
  );
}
