import * as Yup from "yup";

const firstNameRegex = /^[a-zA-Z]*$/;
const lastNameRegex = /^[a-zA-Z\s]*$/;
const phoneregex = /^[6789]\d{9}$/;
const titleRegex = /^[^\d]*$/;
const passwordRegex =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#@$!%*?&^(}){|\-_+="':;,.~`])[A-Za-z\d@$!#%*?&^(}){|\-_+="':;,.~`]{8,}$/;
const emailRegex =
  /^(?!\s)\w+([.-]?\w+)*@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.){1,2}[a-zA-Z]{2,3}))$/;

const isValidMobileNumber = (value) => {
  const mobileRegex = /^[0-9]{10}$/;
  return mobileRegex.test(value) || value.trim() === "";
};

export const loginSchema = Yup.object({
  identifier: Yup.string()
    .required("Please enter your email or mobile number.")
    .test(
      "emailOrMobile",
      "Please enter valid email or mobile number.",
      function (value) {
        return (
          Yup.string().email().isValidSync(value) || isValidMobileNumber(value)
        );
      }
    ),
  password: Yup.string()
    .required("Please enter the password.")
    .trim()
    .min(8, "Must be more than 8 characters.")
    .matches(
      passwordRegex,
      "Password must be combination of lowercase, uppercase, digits & special characters."
    )
    .max(20, "Password cannot be more than 20 characters."),
});

export const forgotPasswordSchema = Yup.object({
  identifier: Yup.string()
    .required("Please enter your email or mobile number.")
    .test(
      "emailOrMobile",
      "Please enter valid email or mobile number.",
      function (value) {
        return (
          Yup.string().email().isValidSync(value) || isValidMobileNumber(value)
        );
      }
    ),
});

export const resetPasswordSchema = Yup.object({
  password: Yup.string()
    .required("Please enter the password.")
    .trim()
    .min(8, "Must be more than 8 characters.")
    .matches(
      passwordRegex,
      "Password must be combination of lowercase, uppercase, digit and special character."
    )
    .max(20, "Password cannot be more than 20 characters."),
  confirmPassword: Yup.string()
    .required("Please enter the confirm password.")
    .trim()
    .oneOf([Yup.ref("password"), null], "Password must match."),
});

export const changePasswordSchema = Yup.object({
  oldPassword: Yup.string()
    .required("Please enter the old password.")
    .trim()
    .min(8, "Must be more than 8 characters.")
    .matches(
      passwordRegex,
      "Password must be combination of lowercase, uppercase, digit and special character."
    )
    .max(16, "Password cannot be more than 16 characters."),

  newPassword: Yup.string()
    .required("Please enter the new password.")
    .trim()
    .min(8, "Must be more than 8 characters.")
    .matches(
      passwordRegex,
      "Password must be combination of lowercase, uppercase, digit and special character."
    )
    .max(16, "Password cannot be more than 16 characters.")
    .test(
      "not-same-as-old-password",
      "New password must be different from the old password",
      function (value) {
        return value !== this.parent.oldPassword;
      }
    ),

  confirmPassword: Yup.string()
    .required("Please enter the confirn password.")
    .trim()
    .oneOf([Yup.ref("newPassword"), null], "Password must match."),
});

export const editAccountSchema = Yup.object({
  firstName: Yup.string()
    .required("Please enter your first name.")
    .trim()
    .min(2, "First name cannot be less than 2 characters.")
    .max(20, "First name cannot be more than 20 characters.")
    .matches(firstNameRegex, "Invalid first name."),

  lastName: Yup.string()
    .required("Please enter your last name.")
    .trim()
    .min(2, "Last name cannot be less than 2 characters.")
    .max(20, "Last name cannot be more than 20 characters.")
    .matches(lastNameRegex, "Invalid last name."),

  email: Yup.string()
    .required("Please enter the email address.")
    .trim()
    .matches(emailRegex, "Please enter valid email id.")
    .max(64, "Email cannot be more than 64 characters.")
    .email("Invalid email address."),
});

export const developerSchema = Yup.object({
  developerName: Yup.string()
    .required("Please enter developer name.")
    .min(2, "Developer name must be at least 2 characters.")
    .max(60, "Developer name can be at most 60 characters.")
    .matches(/^[a-zA-Z\s.'-]+$/, "Please enter a valid developer name."),
});

export const executiveSchema = Yup.object().shape({
  roleType: Yup.string().required("Please select role type."),
  memberType: Yup.string().optional("Please select member type."),
  firstName: Yup.string()
    .required("Please enter your first name.")
    .trim()
    .min(2, "First name cannot be less than 2 characters.")
    .max(20, "First name cannot be more than 20 characters.")
    .matches(firstNameRegex, "Invalid first name."),
  lastName: Yup.string()
    .required("Please enter your last name.")
    .trim()
    .min(2, "Last name cannot be less than 2 characters.")
    .max(20, "Last name cannot be more than 20 characters.")
    .matches(lastNameRegex, "Invalid last name."),
  email: Yup.string()
    .required("Please enter the email.")
    .trim()
    .matches(emailRegex, "Please enter valid email id.")
    .max(64, "Email cannot be more than 64 characters.")
    .email("Invalid email address."),
  phoneNumber: Yup.string()
    .required("Please enter the phone number.")
    .trim()
    .matches(phoneregex, "Invalid phone number.")
    .max(10, "Phone number cannot be more than 10 characters."),
  state: Yup.string().required("Please enter state."),
  city: Yup.string().required("Please enter city."),
  address: Yup.string().required("Please enter address."),
  vp: Yup.string().required("Please select vp."),
  project: Yup.string().optional("Please add project."),
  manager: Yup.string().required("Please select manager."),
});

export const propertySchema = Yup.object().shape({
  selectedAdAccount: Yup.string().required("Please select ads account."),
  selectedPage: Yup.string().required("Please select page."),
  selectedDeveloper: Yup.string().required("Please select property developer."),
  propertyName: Yup.string().required("Please enter property name."),
  areaNumber: Yup.string().optional("Please enter RERA number."),
  areaFt: Yup.string().required("Please enter area (in sq ft)."),
  country: Yup.string().required("Please select country."),
  state: Yup.string().required("Please select state."),
  city: Yup.string().required("Please select city."),
  category: Yup.string().required("Please select category."),
  form: Yup.string().required("Please select form."),
  constructionStatus: Yup.string().required(
    "Please select construction status."
  ),
  listedDate: Yup.date()
    .typeError("Invalid date format. Please select a valid date.")
    .required("Please select the possession date."),
  possessionDate: Yup.date()
    .typeError("Invalid date format. Please select a valid date.")
    .required("Please select the completion date.")
    .min(
      Yup.ref("listedDate"),
      "Completion date cannot be before the listed date."
    ),
  location: Yup.string().required("Please enter location."),
  minPrice: Yup.number()
    .typeError("Please enter a valid number for minimum price.")
    .required("Minimum price is required.")
    .min(0, "Minimum price cannot be negative."),
  maxPrice: Yup.number()
    .typeError("Please enter a valid number for maximum price.")
    .required("Maximum price is required.")
    .min(
      Yup.ref("minPrice"),
      "Maximum price cannot be less than minimum price."
    )
    .min(0, "Maximum price cannot be negative."),
  description: Yup.string().required("Please enter description."),
});

export const editPropertySchema = Yup.object().shape({
  selectedAdAccount: Yup.string().optional("Please select ads account."),
  selectedPage: Yup.string().optional("Please select page."),
  selectedDeveloper: Yup.string().optional("Please select property developer."),
  propertyName: Yup.string().optional("Please enter property name."),
  areaNumber: Yup.string().optional("Please enter RERA number."),
  areaFt: Yup.string().optional("Please enter area (in sq ft)."),
  country: Yup.string().optional("Please select country."),
  state: Yup.string().optional("Please select state."),
  city: Yup.string().optional("Please select city."),
  category: Yup.string().optional("Please select category."),
  form: Yup.string().optional("Please select form."),
  constructionStatus: Yup.string().optional(
    "Please select construction status."
  ),
  listedDate: Yup.date()
    .typeError("Invalid date format. Please select a valid date.")
    .optional("Please select the possession date."),
  possessionDate: Yup.date()
    .typeError("Invalid date format. Please select a valid date.")
    .optional("Please select the completion date.")
    .min(
      Yup.ref("listedDate"),
      "Completion date cannot be before the listed date."
    ),
  location: Yup.string().optional("Please enter location."),
  minPrice: Yup.number()
    .typeError("Please enter a valid number for minimum price.")
    .optional("Minimum price is required.")
    .min(0, "Minimum price cannot be negative."),
  maxPrice: Yup.number()
    .typeError("Please enter a valid number for maximum price.")
    .optional("Maximum price is required.")
    .min(
      Yup.ref("minPrice"),
      "Maximum price cannot be less than minimum price."
    )
    .min(0, "Maximum price cannot be negative."),
  description: Yup.string().optional("Please enter description."),
});

export const managerSchema = Yup.object().shape({
  roleType: Yup.string().required("Please select role type."),
  firstName: Yup.string()
    .required("Please enter your first name.")
    .trim()
    .min(2, "First name cannot be less than 2 characters.")
    .max(20, "First name cannot be more than 20 characters.")
    .matches(firstNameRegex, "Please enter valid first name."),
  lastName: Yup.string()
    .required("Please enter your last name.")
    .trim()
    .min(2, "Last name cannot be less than 2 characters.")
    .max(20, "Last name cannot be more than 20 characters.")
    .matches(lastNameRegex, "Please enter valid last name."),
  email: Yup.string()
    .required("Please enter the email.")
    .trim()
    .matches(emailRegex, "Please enter valid email id.")
    .max(64, "Email cannot be more than 64 characters.")
    .email("Please enter valid email id."),
  phoneNumber: Yup.string()
    .required("Please enter the phone number.")
    .trim()
    .matches(phoneregex, "Please enter valid phone number.")
    .max(10, "Phone number cannot be more than 10 characters."),
  state: Yup.string().required("Please select state."),
  city: Yup.string().required("Please select city."),
  address: Yup.string().required("Please enter address."),
  vp: Yup.string().required("Please select vp."),
  project: Yup.string().optional("Please select project."),
});

export const vpSchema = Yup.object().shape({
  roleType: Yup.string().required("Please select role type."),
  firstName: Yup.string()
    .required("Please enter your first name.")
    .trim()
    .min(2, "First name cannot be less than 2 characters.")
    .max(20, "First name cannot be more than 20 characters.")
    .matches(firstNameRegex, "Please enter valid first name."),
  lastName: Yup.string()
    .required("Please enter your last name.")
    .trim()
    .min(2, "Last name cannot be less than 2 characters.")
    .max(20, "Last name cannot be more than 20 characters.")
    .matches(lastNameRegex, "Please enter valid last name."),
  email: Yup.string()
    .required("Please enter the email.")
    .trim()
    .matches(emailRegex, "Please enter valid email id.")
    .max(64, "Email cannot be more than 64 characters.")
    .email("Invalid email address."),
  phoneNumber: Yup.string()
    .required("Please enter the phone number.")
    .trim()
    .matches(phoneregex, "Please enter valid phone numberr.")
    .max(10, "Phone number cannot be more than 10 characters."),
  state: Yup.string().required("Please select state."),
  city: Yup.string().required("Please select city."),
  address: Yup.string().required("Please enter address."),
});

export const broadcastSchema = Yup.object().shape({
  title: Yup.string()
    .required("Please enter title.")
    .min(4, "Title must be at least 4 characters.")
    .max(30, "Title cannot be more than 30 characters.")
    .matches(titleRegex, "Please enter valid title."),
  broadcastMessage: Yup.string()
    .required("Please enter broadcast message.")
    .max(260, "Broadcast message cannot exceed 260 characters."),
  userType: Yup.string(),
});

export const contentSchema = Yup.object().shape({
  contentType: Yup.string().required("Please enter content type."),
  contentTitle: Yup.string()
    .required("Please enter content title.")
    .min(4, "Content title must be at least 4 characters.")
    .max(30, "Content title cannot be more than 30 characters.")
    .matches(titleRegex, "Please enter valid title."),
  description: Yup.string()
    .required("Please enter description.")
    .min(20, "Description must be at least 20 characters.")
    .max(260, "Description cannot exceed 260 characters."),
  question: Yup.string()
    .optional("Please enter question.")
    .min(4, "Question must be at least 4 characters.")
    .max(30, "Question cannot exceed 30 characters."),
  answer: Yup.string()
    .optional("Please enter answer.")
    .min(4, "Answer must be at least 4 characters.")
    .max(30, "Answer cannot exceed 30 characters."),
});

export const accessTokenSchema = Yup.object().shape({
  accessToken: Yup.string().required("Please enter access token."),
  appId: Yup.string().required("Please enter app id."),
  appSecret: Yup.string().required("Please enter app seret."),
});
