
import React, { useEffect, useState } from "react";
import MainLayout from "../../layout/MainLayout";
import { Paper, Typography, Box, CircularProgress } from "@mui/material";
import ButtonComponent from "../../component/Button";
import { useFormik } from "formik";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { postAPIHandler, putAPIHandler } from "../../config/service";
import { toast,  } from "react-hot-toast";
import { editAccountSchema } from "../../schemas";
import profileImg from "../../assets/profileImg.png";
import editIcon from "../../assets/Edit.png"
import arrowBack from "../../assets/arrowBack.svg";
import InputField from "../../component/InputField";
import typographyStyles from "../../theme/Typography";

const accountStyle = {
  editAccountContainer: {
    marginTop: "20px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  accountTitle: {
    ...typographyStyles.h4,
  },
  accountLabel: {
    ...typographyStyles.h6,
  },
  editAccountContent: {
    display: "flex",
    justifyContent: "center",
    paddingTop: "20px",
  },
  editAccountMainHeading: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
  },
  accountHeading: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "1%",
    gap: "1%",
  },
  editAccountImgSection: {
    paddingRight: "20px",
    borderRight: "1px solid rgba(0, 0, 0, 0.2)",
  },
  loader: {
    position: "absolute",
    top: "40%",
    left: "40%",
    transform: "translate(-50%, -50%)",
    color:"rgba(0, 176, 80, 1)"
  },
  editIcon: {
    // position: "absolute",
    // top: "50%",
    // left: "50%",
    transform: "translate(-50%, -50%)",
    cursor: "pointer",
  },
  editAccountInfoSection: {
    width: "60%",
    paddingLeft: "20px",
    display: "flex",
    flexDirection: "column",
    gap: "20px",
  },
  editAccountinfoBox: {
    display: "flex",
    flexDirection: "column",
    gap: "4px",
  },
  editAccountBtnSection: {
    padding: "0 20%",
    display: "flex",
    gap: "5%",
  },
};

const EditProfile = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { id } = useParams();

  const { fullName, email, image } = location.state || {};
  const [profileImage, setProfileImage] = useState(image || profileImg);
  const [loading, setLoading] = useState(false);

  const initialValues = {
    firstName: fullName?.split(" ")[0] || "",
    lastName: fullName?.split(" ")[1] || "",
    email: email || "",
  };

  const {
    values,
    errors,
    handleBlur,
    handleChange,
    touched,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: editAccountSchema,
    onSubmit: (values) => {
      updateProfile(values);
    },
  });

  useEffect(() => {
    setFieldValue("email", email);
  }, [email, setFieldValue]);

  const handleKeyDown = (event) => {
    if (event.key === " ") {
      event.preventDefault();
    }
  };

  const updateProfile = async (values) => {
    try {
      let updatedData = {
        userId: id,
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
      };

      if (profileImage) {
        updatedData.profileImage = profileImage;
      }

      const response = await putAPIHandler({
        endPoint: "updateProfile",
        dataToSend: updatedData,
      });

    
      const updatedUserData = response.data.result;
      const userDataInStorage = JSON.parse(sessionStorage.getItem("user"));
      userDataInStorage.fullName = updatedUserData.fullName;
      userDataInStorage.profileImage = updatedUserData.profileImage;

      sessionStorage.setItem("user", JSON.stringify(userDataInStorage));
      if (response && response.data.responseCode === 200) {
        toast.success("Profile updated successfully")
      navigate("/myaccount");
      }else{
        toast.error(response.data.responseMessage)
      }
      
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleProfileImageChange = async (event) => {
    const file = event.currentTarget.files[0];
    if (file) {
      if (file.type.startsWith("image/")) {
        try {

          setLoading(true)
          const formData = new FormData();
          formData.append("file", file);

          const res = await postAPIHandler({
            endPoint: "uploadFile",
            dataToSend: formData,
          });

          setProfileImage(res.data.result);
          toast.success("Image uploaded successfully!");
        } catch (error) {
          console.error("Error uploading file:", error);
        } finally {
          setLoading(false); 
        }
      } else {
        alert("Please select an profileImage file.");
      }
    }
  };

  return (
    <MainLayout>
      <Box style={{ padding: "1rem" }}>

        <Box sx={accountStyle.editAccountMainHeading}>
          <Typography style={{ fontWeight: 700, fontSize: "30px" }}>
            My Account
          </Typography>
        </Box>

        <Paper elevation={0}>

          <Box sx={accountStyle.accountHeading}>
            <Link to={"/myaccount"}>
              <img
                src={arrowBack}
                alt="arroWback"
                style={{
                  width: "2.813rem",
                  height: "2.813rem",
                  cursor: "pointer",
                }}
              />
            </Link>
            <Box sx={accountStyle.accountTitle}>Edit Profile</Box>
          </Box>

          <Box sx={accountStyle.editAccountContainer}>

            <form
              style={accountStyle.editAccountContent}
              onSubmit={handleSubmit}
            >
              <Box sx={accountStyle.editAccountImgSection}>
                <label
                  htmlFor="upload-image"
                  style={{
                    cursor: "pointer",
                    display: "inline-block",
                    position: "relative",
                  }}
                > {loading && (
                  <CircularProgress size={50} style={accountStyle.loader} />
                )}
                  {profileImage && (
                    <img
                      src={profileImage}
                      alt="ProfileImg"
                      className="profile-img"
                      style={{
                        cursor: "pointer",
                        width: "187px",
                        height: "187px",
                        objectFit: "cover",
                        borderRadius: "50%",
                        // border:"1px solid red"
                      }}
                    />
                  )}
                  {!loading && (
                    <img
                      src={editIcon}
                      alt="Edit Icon"
                      style={accountStyle.editIcon}
                    />
                  )}
                  <input
                    type="file"
                    id="upload-image"
                    accept="image/*"
                    onChange={handleProfileImageChange}
                    style={{ display: "none" }}
                  />
                </label>
              </Box>


              <Box sx={accountStyle.editAccountInfoSection}>
                <Box sx={accountStyle.editAccountinfoBox}>
                  <Typography sx={accountStyle.accountLabel}>
                    First Name
                  </Typography>
                  <InputField
                    className="authTextField"
                    name="firstName"
                    type="text"
                    value={values.firstName}
                    maxLength={64}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder="Enter the first name"
                    onKeyDown={handleKeyDown}
                  />
                  {errors.firstName && touched.firstName ? (
                    <p
                      style={{ color: "red", marginTop: "5px", float: "left" }}
                    >
                      {errors.firstName}
                    </p>
                  ) : null}
                </Box>
                <Box sx={accountStyle.editAccountinfoBox}>
                  <Typography sx={accountStyle.accountLabel}>
                    Last Name
                  </Typography>
                  <InputField
                    className="authTextField"
                    name="lastName"
                    type="text"
                    value={values.lastName}
                    maxLength={64}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder="Enter the last name"
                  />
                  {errors.lastName && touched.lastName ? (
                    <p
                      style={{ color: "red", marginTop: "5px", float: "left" }}
                    >
                      {errors.lastName}
                    </p>
                  ) : null}
                </Box>
                <Box sx={accountStyle.editAccountinfoBox}>
                  <Typography sx={accountStyle.accountLabel}>
                    Email Address
                  </Typography>
                  <InputField
                    className="authTextField"
                    name="email"
                    type="text"
                    value={values.email}
                    placeholder="Enter the email address"
                    disabled
                  />
                  {errors.email && touched.email ? (
                    <p
                      style={{ color: "red", marginTop: "5px", float: "left" }}
                    >
                      {errors.email}
                    </p>
                  ) : null}
                </Box>
                <Box sx={accountStyle.editAccountBtnSection}>
                  <ButtonComponent name={"Submit"} onClick={handleSubmit} />
                </Box>
                
              </Box>
            </form>


          </Box>


        </Paper>

      </Box>

    </MainLayout>
  );
};

export default EditProfile;
