import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import MainLayout from "../../../layout/MainLayout";
import { Paper, Box, Typography, CircularProgress } from "@mui/material";
import arrowBack from "../../../assets/arrowBack.svg";
import Input from "@mui/joy/Input";
import Textarea from "@mui/joy/Textarea";
import "./UpdateVP.css";
import Select from "react-select";
import ButtonComponent from "../../../component/Button";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  getAPIHandler,
  postAPIHandler,
  putAPIHandler,
} from "../../../config/service";
import { vpSchema } from "../../../schemas";
import { State, City } from "country-state-city";
import { toast } from "react-hot-toast";
import typographyStyles from "../../../theme/Typography";
import defaultProfileIcon from "../../../assets/profileImg.png";
import editIcon from "../../../assets/Edit.png";

const accountStyle = {
  editAccountContainer: {
    marginTop: "20px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  accountTitle: {
    ...typographyStyles.h4,
  },
  accountLabel: {
    ...typographyStyles.h6,
  },
  editAccountContent: {
    display: "flex",
    justifyContent: "center",
    paddingTop: "20px",
  },
  editAccountMainHeading: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
  },
  accountHeading: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "1%",
    gap: "1%",
  },
  editAccountImgSection: {
    paddingRight: "20px",
    borderRight: "1px solid rgba(0, 0, 0, 0.2)",
  },
  loader: {
    position: "absolute",
    top: "40%",
    left: "40%",
    transform: "translate(-25%, -25%)",
    color: "rgba(0, 176, 80, 1)",
  },
  editIcon: {
    transform: "translate(-25%, -25%)",
    cursor: "pointer",
  },
  editAccountInfoSection: {
    width: "60%",
    paddingLeft: "20px",
    display: "flex",
    flexDirection: "column",
    gap: "20px",
  },
  editAccountinfoBox: {
    display: "flex",
    flexDirection: "column",
    gap: "4px",
  },
  editAccountBtnSection: {
    padding: "0 20%",
    display: "flex",
    gap: "5%",
  },
};

const EditVP = () => {
  const [data, setData] = React.useState(null);
  const [stateOptions, setStateOptions] = useState([]);
  const [cityOptions, setCityOptions] = useState([]);
  const [profileImage, setProfileImage] = useState();
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const { id } = useParams();

  useEffect(() => {
    fetchStateOptions();
    getData();
  }, []);

  const fetchStateOptions = () => {
    const states = State.getStatesOfCountry("IN").map((state) => ({
      value: state.isoCode,
      label: state.name,
    }));
    setStateOptions(states);
  };

  const handleStateChange = (selectedState, formik) => {
    if (!selectedState) {
      setCityOptions([]);
      formik.setFieldValue("city", "");
      return;
    }

    const cities = City.getCitiesOfState("IN", selectedState.value).map(
      (city) => ({
        value: city.name,
        label: city.name,
      })
    );
    setCityOptions(cities);
    formik.setFieldValue("state", selectedState.label);
    formik.setFieldValue("city", "");
  };

  const onSubmit = async (values, { setSubmitting }) => {
    if (!profileImage) {
      toast.error("Please upload a profile image.");
      setSubmitting(false);
      return;
    }
    try {
      const response = await putAPIHandler({
        endPoint: "updateProfile",
        dataToSend: {
          userId: id,
          userType: "VP",
          userRoleType: values.roleType,
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
          mobileNumber: values.phoneNumber,
          state: values.state,
          city: values.city,
          address: values.address,
          profileImage: profileImage,
        },
      });

      console.log(response.data.result);
      setSubmitting(false);
      toast.success("Profile Updated Successfully");
      navigate("/vpmanagement");
    } catch (error) {
      console.error("Error:", error);
      setSubmitting(false);
    }
  };

  const getData = async () => {
    try {
      const response = await getAPIHandler({
        endPoint: "viewProfile",
        paramsData: {
          userId: id,
        },
      });

      console.log("Response:", response.data);
      setData(response.data.result);
      console.log("Profile Image:", response.data.result.profileImage);

      setProfileImage(response.data.result.profileImage); 
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleProfileImageChange = async (event) => {
    const file = event.currentTarget.files[0];
    if (file) {
      if (file.type.startsWith("image/")) {
        try {
          setLoading(true);
          const formData = new FormData();
          formData.append("file", file);

          const res = await postAPIHandler({
            endPoint: "uploadFile",
            dataToSend: formData,
          });

          setProfileImage(res.data.result);
          toast.success("Image uploaded successfully!");
        } catch (error) {
          console.error("Error uploading file:", error);
          toast.error("Failed to upload image.");
        } finally {
          setLoading(false);
        }
      } else {
        toast.error("Please select a valid image file.");
      }
    }
  };

  return (
    <MainLayout>
      <Box style={{ padding: "1rem" }}>
        <Box sx={accountStyle.editAccountMainHeading}>
          <Typography style={{ fontWeight: 700, fontSize: "30px" }}>
            VP Management
          </Typography>
        </Box>

        <Paper elevation={0}>
          <Box sx={accountStyle.accountHeading}>
            <Link to={"/vpmanagement"}>
              <img
                src={arrowBack}
                alt="arroWback"
                style={{
                  width: "2.813rem",
                  height: "2.813rem",
                  cursor: "pointer",
                }}
              />
            </Link>
            <Box sx={accountStyle.accountTitle}> Update Vice President</Box>
          </Box>

          <Box display="flex" justifyContent="center" margin="2rem">
            <Typography
              variant="h6"
              sx={{ marginLeft: "1rem", fontWeight: 700 }}
            >
              Member Details
            </Typography>
          </Box>

          <Box sx={accountStyle.editAccountContainer}>
            <Formik
              initialValues={{
                roleType: data ? data.userRoleType : "",
                firstName: data ? data.firstName : "",
                lastName: data ? data.lastName : "",
                email: data ? data.email : "",
                phoneNumber: data ? data.mobileNumber : "",
                state: data ? data.state : "",
                city: data ? data.city : "",
                address: data ? data.address : "",
                profileImage: profileImage || (data ? data.profileImage : ""),
              }}
              enableReinitialize={true}
              validationSchema={vpSchema}
              onSubmit={onSubmit}
            >
              {({ isSubmitting, setFieldValue, handleBlur, values }) => (
                <Form style={accountStyle.editAccountContent}>
                  <Box sx={accountStyle.editAccountImgSection}>
                    <label
                      htmlFor="upload-image"
                      style={{
                        cursor: "pointer",
                        display: "inline-block",
                        position: "relative",
                      }}
                    >
                      {loading && (
                        <CircularProgress
                          size={50}
                          style={{
                            position: "absolute", // Position the loader absolutely within the image container
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)", // Center the loader
                          }}
                        />
                      )}
                      {/* Conditional Rendering for Profile Image or Default Icon */}
                      {!loading && !profileImage && (
                        <img
                          src={defaultProfileIcon} // Default icon when no image is uploaded
                          alt="ProfileIcon"
                          className="profile-img"
                          style={{
                            cursor: "pointer",
                            width: "187px",
                            height: "187px",
                            objectFit: "cover",
                            borderRadius: "50%",
                          }}
                        />
                      )}
                      {profileImage && !loading && (
                        <img
                          src={profileImage}
                          alt="ProfileImg"
                          className="profile-img"
                          style={{
                            cursor: "pointer",
                            width: "187px",
                            height: "187px",
                            objectFit: "cover",
                            borderRadius: "50%",
                          }}
                        />
                      )}
                      {!loading && !profileImage && (
                        <img
                          src={editIcon}
                          alt="Edit Icon"
                          style={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)", 
                            cursor: "pointer",
                          }}
                        />
                      )}
                      <input
                        type="file"
                        id="upload-image"
                        accept="image/*"
                        onChange={handleProfileImageChange}
                        style={{ display: "none" }}
                      />
                    </label>
                  </Box>

                  <Box sx={accountStyle.editAccountInfoSection}>
                    <Box sx={accountStyle.editAccountinfoBox}>
                      <div className="role-type">
                        <label>
                          <strong>Role Type</strong>
                        </label>

                        <label>
                          <Field type="radio" name="roleType" value="SALES" />
                          Sales
                        </label>
                        <label>
                          <Field
                            type="radio"
                            name="roleType"
                            value="PRE_SALES"
                          />
                          Presales
                        </label>
                      </div>
                      <ErrorMessage
                        name="roleType"
                        component="div"
                        className="error"
                      />
                    </Box>
                    <Box sx={accountStyle.editAccountinfoBox}>
                      <Field
                        as={Input}
                        type="text"
                        name="firstName"
                        placeholder="Enter first name"
                      />
                      <ErrorMessage
                        name="firstName"
                        component="div"
                        className="error"
                      />
                    </Box>
                    <Box sx={accountStyle.editAccountinfoBox}>
                      <Field
                        as={Input}
                        type="text"
                        name="lastName"
                        placeholder="Enter last name"
                      />
                      <ErrorMessage
                        name="lastName"
                        component="div"
                        className="error"
                      />
                    </Box>
                    <Box sx={accountStyle.editAccountinfoBox}>
                      <Field
                        as={Input}
                        type="email"
                        name="email"
                        placeholder="Enter email address"
                      />
                      <ErrorMessage
                        name="email"
                        component="div"
                        className="error"
                      />
                    </Box>
                    <Box sx={accountStyle.editAccountinfoBox}>
                      <Field
                        as={Input}
                        type="tel"
                        name="phoneNumber"
                        placeholder="Enter phone number"
                      />
                      <ErrorMessage
                        name="phoneNumber"
                        component="div"
                        className="error"
                      />
                    </Box>
                    <Box sx={accountStyle.editAccountinfoBox}>
                      <div className="s-select">
                        <Select
                          id="state"
                          name="state"
                          options={stateOptions}
                          value={stateOptions.find(
                            (option) => option.label === values.state
                          )}
                          onChange={(selectedOption) => {
                            setFieldValue(
                              "state",
                              selectedOption ? selectedOption.label : ""
                            );
                            handleStateChange(selectedOption, {
                              setFieldValue,
                            });
                          }}
                          onBlur={handleBlur}
                          isClearable={true}
                          placeholder="Select State"
                        />
                        <ErrorMessage
                          name="state"
                          component="div"
                          className="error"
                        />
                      </div>
                    </Box>
                    <Box sx={accountStyle.editAccountinfoBox}>
                      <div className="s-select">
                        <Select
                          id="city"
                          name="city"
                          options={cityOptions}
                          value={
                            cityOptions.find(
                              (option) => option.label === values.city
                            ) || ""
                          }
                          onChange={(selectedOption) =>
                            setFieldValue(
                              "city",
                              selectedOption ? selectedOption.label : ""
                            )
                          }
                          onBlur={handleBlur}
                          isClearable={true}
                          placeholder="Select City"
                        />
                        <ErrorMessage
                          name="state"
                          component="div"
                          className="error"
                        />
                      </div>
                    </Box>
                    <Box sx={accountStyle.editAccountinfoBox}>
                      <Field
                        as={Textarea}
                        name="address"
                        placeholder="Address"
                      />
                      <ErrorMessage
                        name="address"
                        component="div"
                        className="error"
                      />
                    </Box>
                    <Box sx={accountStyle.editAccountinfoBox}>
                      <ButtonComponent
                        type="submit"
                        name={"Update Member"}
                        disabled={isSubmitting}
                        height="43px"
                        fullWidth
                      />
                    </Box>
                  </Box>
                </Form>
              )}
            </Formik>
          </Box>
        </Paper>
      </Box>
    </MainLayout>
  );
};

export default EditVP;
