import React, { useState, useEffect } from "react";
import MainLayout from "../../../layout/MainLayout";
import ViewDetail from "../../../component/viewDetail/ViewDetail";
import { Paper, Grid, Typography } from "@mui/material";
import arrowBack from "../../../assets/arrowBack.svg"
import { useNavigate, useParams } from "react-router-dom";
import { getAPIHandler } from "../../../config/service";

const ViewAssignedProperty = () => {
  const [data, setData] = useState(null);
  const [selectedImage, setSelectedImage] = useState(0);
  const [customImage, setCustomImage] = useState(null);
  const [defaultImages, setDefaultImages] = useState([]);

  const { id } = useParams();
  const navigate = useNavigate();

  const getData = async () => {
    try {
      const response = await getAPIHandler({
        endPoint: "viewPropertyDetails",
        paramsData: {
          propertyId: id,
        },
      });

      console.log("Response:", response.data);
      setData(response.data.result[0]);

      if (
        response.data.result[0].filesURL &&
        response.data.result[0].filesURL.length > 0
      ) {
        const images = response.data.result[0].filesURL.map((file) => file.url);
        setDefaultImages(images);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const propertyDetails = [
    { left: "Property Id", value: data ? data._id : null },
    { left: "Property Name", value: data ? data.propertyName : null },
    { left: "Country", value: data ? data.country : null },
    { left: "State", value: data ? data.state : null },
    { left: "City", value: data ? data.city : null },
    { left: "Category", value: data ? data.category : null },
    {
      left: "Construction Status",
      value: data ? data.constructionStatus : null,
    },
    { left: "Created Date & Time", value: data ? data.createdAt : null },
    { left: "Status", value: data ? data.status : null },
  ];

  const handleThumbnailClick = (index) => {
    setSelectedImage(index);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setCustomImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleBackClick = () => {
    navigate(-1)
  };

  return (
    <MainLayout>
      <Grid>
        <Grid
          style={{
            display: "flex",
            alignItems: "center",
            padding: "1rem",
          }}
        >
          <Typography style={{ fontWeight: 700, fontSize: "30px" }}>
            Property Management
          </Typography>
        </Grid>
        <Grid style={{ marginLeft: "15px", marginBottom: "15px" }}>
          <Paper elevation={0}>
            <div className="icon">
            <img
            src={arrowBack}
            alt="arrow back"
            style={{
              width: "2.813rem",
              height: "2.813rem",
              cursor: "pointer",
            }}
            onClick={handleBackClick}
          />
            </div>
            <div className="viewProject-container">
              <div className="viewproject-content">
                <div
                  className="image-section"
                  onDrop={handleDrop}
                  onDragOver={handleDragOver}
                >
                  {customImage ? (
                    <img src={customImage} alt="Custom" />
                  ) : (
                    <img
                      src={
                        defaultImages.length > 0
                          ? defaultImages[selectedImage]
                          : ""
                      }
                      alt="Property"
                      onDoubleClick={() =>
                        document.getElementById("imageInput").click()
                      }
                    />
                  )}

                  <div className="thumbnails">
                    {defaultImages.map((image, index) => (
                      <img
                        key={index}
                        src={image}
                        alt="Thumbnail"
                        className={index === selectedImage ? "active" : ""}
                        onClick={() => handleThumbnailClick(index)}
                      />
                    ))}
                  </div>
                </div>
              </div>

              <div className="viewproject-content">
                <h2>Property Detail</h2>
                <ViewDetail type="property" details={propertyDetails} />
              </div>
            </div>
          </Paper>
        </Grid>
      </Grid>
    </MainLayout>
  );
};

export default ViewAssignedProperty;
