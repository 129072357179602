import React, { useEffect, useState } from "react";
import MainLayout from "../../../layout/MainLayout";
import { Grid, Typography, Paper } from "@mui/material";
import arrowBack from "../../../assets/arrowBack.svg";
import { Link, useParams } from "react-router-dom";
import ViewDetail from "../../../component/viewDetail/ViewDetail";
import { getAPIHandler } from "../../../config/service";

const ViewBroadcast = () => {
  const { id } = useParams();
  const [data, setData] = useState({});

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const response = await getAPIHandler({
        endPoint: "getBroadcastById",
        paramsData: {
          broadcastId: id,
        },
      });

      console.log("Response:", response.data);
      setData(response.data.result);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const formattedDate = data.createdAt
  ? new Date(data.createdAt).toLocaleDateString("en-IN", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    })
  : "";

const formattedTime = data.createdAt
  ? new Date(data.createdAt).toLocaleTimeString("en-IN", {
      hour: "2-digit",
      minute: "2-digit",
    })
  : "";

const amPm = data.createdAt
  ? new Date(data.createdAt).toLocaleTimeString("en-IN", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    }).slice(-2)
  : "";



  const formattedDateTime = `${formattedDate}, ${formattedTime} ${amPm}`;

  
  const broadcastDetails = [
    { left: "Broadcast Id", value: data ? data._id : null },
    { left: "Title", value: data ? data.title : null },
    { left: "Broadcast Message", value: data ? data.broadcastMessage : null },
    { left: "Created Date & Time", value: data ? formattedDateTime : null },
    { left: "User Type", value: data ? data.userType : null },
  ];

  return (
    <MainLayout>
      <Grid>
        <Grid
          style={{
            display: "flex",

            alignItems: "center",
            padding: "1rem",
          }}
        >
          <Typography style={{ fontWeight: 700, fontSize: "30px" }}>
            Broadcast Management
          </Typography>
        </Grid>
        <Grid style={{ marginLeft: "15px", marginBottom: "15px" }}>
          <Paper elevation={0}>
            <div className="icon">
              <Link to={"/broadcastmanagement"}>
              <img
              src={arrowBack}
              alt="arroWback"
              style={{
                width: "2.813rem",
                height: "2.813rem",
                cursor: "pointer",
              }}
            />
              </Link>
            </div>
            <div className="ViewDeveloper-container">
              <h2>View Broadcast Message</h2>
              <ViewDetail type="broadcast" details={broadcastDetails} />
            </div>
          </Paper>
        </Grid>
      </Grid>
    </MainLayout>
  );
};

export default ViewBroadcast;
