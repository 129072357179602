import React, { useEffect, useState } from "react";
import {
  ResponsiveContainer,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
} from "recharts";
import { getAPIHandler } from "../../config/service";
import { Box, CircularProgress, Typography } from "@mui/material";

const BarGraph = ({ selectedYear }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const getLeadData = async () => {
    setLoading(true);
    try {
      const response = await getAPIHandler({
        endPoint: "getLeadsFromFacebook",
      });

      const properties = response.data.result;
      const leadsByMonth = {
        Jan: 0,
        Feb: 0,
        Mar: 0,
        Apr: 0,
        May: 0,
        Jun: 0,
        Jul: 0,
        Aug: 0,
        Sep: 0,
        Oct: 0,
        Nov: 0,
        Dec: 0,
      };

      properties.forEach((property) => {
        const leads = property.PropertyDetails.generatedLeads;

        leads.forEach((lead) => {
          const leadDate = new Date(lead.created_time);
          const leadYear = leadDate.getFullYear();
          const month = leadDate.toLocaleString("default", { month: "short" });

          if (leadYear === selectedYear) {
            if (leadsByMonth[month] !== undefined) {
              leadsByMonth[month] += 1;
            }
          }
        });
      });

      const formattedData = Object.keys(leadsByMonth).map((month) => ({
        name: month, 
        leadRequests: leadsByMonth[month],
      }));

      setData(formattedData); 
    } catch (error) {
      console.error("Error:", error);
    }
    setLoading(false);
  };

  useEffect(() => {
    getLeadData();
  }, [selectedYear]);

  return (
    <Box sx={{ margin: "10px" }}>
      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '400px' }}>
          <CircularProgress sx={{color: 'rgb(0, 176, 80)'}} />
        </Box>
      ) : data.length === 0 ? (
        <Typography variant="h6" align="center">
          No Data Found
        </Typography>
      ) : (
        <ResponsiveContainer width="100%" aspect={2.5}>
          <BarChart data={data} width={400} height={400}>
            <XAxis dataKey="name" tick={{ fontSize: "16px" }} />
            <YAxis tick={{ fontSize: "16px" }} />
            <Tooltip />
            <defs>
              <linearGradient id="customGradient" x1="0" y1="0" x2="0" y2="1">
                <stop offset="0%" stopColor="rgba(0, 176, 80, 0.83)" />
                <stop offset="100%" stopColor="rgba(255, 255, 255, 1)" />
              </linearGradient>
            </defs>
            <Bar
              dataKey="leadRequests"
              fill="url(#customGradient)"
              barSize={50}
              radius={[8, 8, 0, 0]}
            />
          </BarChart>
        </ResponsiveContainer>
      )}
    </Box>
  );
};

export default BarGraph;
