import React from "react";
import logo from "../assets/Logo.png";
import { Grid, Paper } from "@mui/material";


const MainHeader = () => {
  const headerContentStyle = {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "1rem",
  };

  const paperStyle = {
    height:"100%"
  };



  return (
    
      <Paper elevation={0}  style={paperStyle}>
        <Grid style={headerContentStyle}>
          <img src={logo} alt="logo" />
        </Grid>
      </Paper>

  );
};

export default MainHeader;
