import React, { useState, useEffect } from "react";
import MainLayout from "../../layout/MainLayout";
import { Avatar, Paper, Typography, Box } from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import SettingsIcon from "@mui/icons-material/Settings";
import { useNavigate } from "react-router-dom";
import { getAPIHandler } from "../../config/service";
import typographyStyles from "../../theme/Typography"


const accountStyle = {
  accountContainer: {
    marginTop: "20px",
    display: "flex",
    flexDirection: "column",
    gap: "20px",
  },

  accountTitle: {
    ...typographyStyles.h4,
    fontWeight: "600",
    padding: "1%"
  },

  accountContent: {
    display: "flex",
    gap: "10%",
  },

  accountInfo: {
    display: "flex",
    flexDirection: "column",
    gap: "20px",
    width: "100%",
  },

  adminImgContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "10px",
  },

  adminImg: {
    height: "12rem",
    width: "12rem",
  },

  adminInfoContainer: {
    display: "flex",
    gap: "10%",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
  },

  accountText: {
    ...typographyStyles.h6,
    fontWeight: "600",
  },


  accountlabel: {
    ...typographyStyles.h6,
    fontWeight: "600",
    color: "rgb(128,128,128)",
  },

  accountSettingContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    gap: "20px",
    cursor: "pointer",
  },

  accountSettingContent: {
    display: "flex",
    alignItems: "center",
    gap: "5%",
  },

  accountIcon: {
    color: "rgba(0, 176, 80, 1)",
    fontSize: "40px",
  },

  accountLabel: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
  },
};

const MyAccount = () => {

  const [data, setData] = useState(null);
  const navigate = useNavigate()

  const getData = async () => {
    try {
      const response = await getAPIHandler({
        endPoint : "viewProfile"
      })

      setData(response.data.result);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  

  return (
    <MainLayout>
      <Box style={{ padding: "1rem" }}>
        <Box
          style={{
            display: "flex",

            justifyContent: "start",
            alignItems: "center",
          }}
        >
          <Typography style={{ fontWeight: 700, fontSize: "30px" }}>
            My Account
          </Typography>
        </Box>
        <Paper elevation={0}>
          <Box sx={accountStyle.accountContainer}>
          <Box sx={accountStyle.accountTitle}>
            
          User Profile
          </Box>
     
          <Box sx={accountStyle.accountContent}
          >
          
            {data && (
              <Box sx={accountStyle.accountInfo}>
          
              <Box sx={accountStyle.adminImgContainer}>
              <Avatar
                style={accountStyle.adminImg}
                src={data.profileImage}
              />
            </Box>
            <Box sx={accountStyle.adminInfoContainer}>
              <Box sx={accountStyle.accountLabel}>
                <Typography sx={accountStyle.accountlabel}>Name:</Typography>
                <Typography sx={accountStyle.accountlabel}>
                  Email Address:
                </Typography>
              </Box>
              <Box sx={accountStyle.accountLabel}>
                <Typography sx={accountStyle.accountText}>
                  {data.fullName}
                </Typography>
                <Typography sx={accountStyle.accountText}>
                  {data.email}
                </Typography>
              </Box>
            </Box>

            </Box>
            )}
            
            <Box sx={accountStyle.accountSettingContainer}>
            {data && (
              <Box
              sx={accountStyle.accountSettingContent}
              onClick={() =>
                navigate(`./editprofile/${data._id}`, {
                  state: { fullName : data.fullName, image: data.profileImage, email : data.email},
                })
              }
            >
              <AccountCircleIcon sx={accountStyle.accountIcon} />
              <Typography sx={accountStyle.accountText}>
                Edit Profile
              </Typography>
            </Box>
            )}
            {data && (
              <Box
              sx={accountStyle.accountSettingContent}
              onClick={() => {
                navigate(`./changepassword/${data._id}`, {state : {oldPass : data.password}});
              }}
            >
              <SettingsIcon sx={accountStyle.accountIcon} />
              <Typography sx={accountStyle.accountText}>
                Change Password
              </Typography>
            </Box>
            )}
          </Box>

          </Box>
          </Box>
        </Paper>
      </Box>
    </MainLayout>
  );
};

export default MyAccount;




