import React from "react";
import "./Card.css";

const Card = (props) => {
  return (
    <div className="Card-container" style={{ background: props.background }}>
      <div className="card-wrapper">
        <div className="card-content">
          <div className="visit">
            <div><strong>Requested Date & Time:</strong></div>
            <div>{props.visit}</div>
            
          </div>
          <div className="property-name">
            <strong>Property Name:</strong> {props.propertyName}
          </div>
          <div className="developer">
            <div className="developer-sub"><strong>Developer Name:</strong> {props.developer}</div>
            <div> <strong>Team Member :</strong> {props.teamMember}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Card;
