import React, { useState } from "react";
import MainLayout from "../../layout/MainLayout";
import CustomizedTables from "../../component/Table/PropertyManagementData";
import PropertyFilter from "../../component/Filters/PropertyFilter";
import ButtonComponent from "../../component/Button";
import { Grid, Typography, Paper } from "@mui/material";
import { useNavigate } from "react-router-dom";

const PropertyManagement = () => {
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState("");
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [category, setCategory] = useState("");
  const [constructionStatus, setConstructionStatus] = useState("");

  const userData = JSON.parse(sessionStorage.getItem("user"));
  const { userType } = userData || {};

  const handleSearch = (query) => {
    setSearchQuery(query);
  };

  const handleFromDateChange = (date) => {
    setFromDate(date);
  };

  const handleToDateChange = (date) => {
    setToDate(date);
  };

  const handleTypeSelect = (category) => {
    setCategory(category);
  };

  const handleStatusSelect = (status) => {
    setConstructionStatus(status);
  };

  const handleDownloadCSV = () => {
    const downloadFunction = document.getElementById("property-tablesdata-download");
    if (downloadFunction) {
      downloadFunction.click();
    }
  };

  return (
    <MainLayout>
      <Grid>


      
        <Grid
          container
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            ml: 2,
          }}
        >
          <Typography sx={{ fontWeight: 700, fontSize: "30px" }}>
            Property Management
          </Typography>

          <Grid>
            <ButtonComponent
              name={"Download CSV"}
              borderRadius={"100px"}
              backgroundColor={"rgba(0, 0, 0, 0.05)"}
              onClick={handleDownloadCSV}
              color={"black"}
            />
            <ButtonComponent
              name={"+Add New Property"}
              borderRadius={"100px"}
              onClick={() => navigate("/propertymanagement/addproperty")}
              disabled={userType === "ADMIN"}
            />
          </Grid>
        </Grid>
        
        <Grid sx={{ mb: 2, ml: 2 }}>
          <Paper elevation={0}>
            <Grid sx={{ p: 2 }}>
              <PropertyFilter
                searchPlaceholder={"Search by property name"}
                onSearch={handleSearch}
                onFromDateChange={handleFromDateChange}
                onToDateChange={handleToDateChange}
                onConstructionStatusChange={handleStatusSelect}
                onCategoryChange={handleTypeSelect}
              />
            </Grid>

            <Grid sx={{ p: 2 }}>
              <CustomizedTables
                searchQuery={searchQuery}
                fromDate={fromDate}
                toDate={toDate}
                category={category}
                constructionStatus={constructionStatus}
                setDownloadCSVFunction={(func) => {
                  document.getElementById("property-tablesdata-download").onclick = func;
                }}
                const showEditAndBlockIcons={true}
              />
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </MainLayout>
  );
};

export default PropertyManagement;
