import React from 'react';
import { styled } from '@mui/system';

const Bubble = styled('div')(({ color, size, delay }) => ({
  backgroundColor: color || '#FFF', 
  borderRadius: '50%',
  width: size || '1rem',  
  height: size || '1rem', 
  margin: '0 0.2rem',
  animation: `grow 1.2s infinite ${delay} ease-in-out`,
  '@keyframes grow': {
    '0%': {
      transform: 'scale(0.7)', 
    },
    '50%': {
      transform: 'scale(1.2)',
    },
    '100%': {
      transform: 'scale(0.7)', 
    },
  },
}));

const BubbleLoader = ({ color, size }) => (
  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center',}}>
    <Bubble color={color} size={size} delay="0s" />  
    <Bubble color={color} size={size} delay="0.2s" /> 
    <Bubble color={color} size={size} delay="0.4s" />
  </div>
);

export default BubbleLoader;
