import axios from "axios";
import { ApiConfig } from "./ApiConfig";

export const postAPIHandler = async ({ endPoint, dataToSend, paramsData }) => {
  try {
    return await axios({
      method: "POST",
      url: ApiConfig[endPoint],
      headers: {
        token: window.sessionStorage.getItem("token"),
      },
      data: dataToSend ? dataToSend : null,
      params: paramsData ? paramsData : null,
    });
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const postAPIHandlerWithoutToken = async ({ endPoint, dataToSend }) => {
    try {
      return await axios({
        method: "POST",
        url: ApiConfig[endPoint],
        data: dataToSend ? dataToSend : null,
      
      });
    } catch (error) {
      console.log(error);
      return error.response;
    }
  };

  export const getAPIHandlerWithToken = async ({ endPoint }) => {
    try {
      return await axios({
        method: "GET",
        url: ApiConfig[endPoint]
      });
    } catch (error) {
      console.log(error);
    }
  };
  

export const putAPIHandler = async ({ endPoint, dataToSend, paramsData }) => {
  try {
    return await axios({
      method: "PUT",
      url: ApiConfig[endPoint],
      headers: {
        token: window.sessionStorage.getItem("token"),
      },
      data: dataToSend ? dataToSend : null,
      params: paramsData ? paramsData : null,
    });
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const deleteAPIHandler = async ({
  endPoint,
  dataToSend,
  paramsData,
}) => {
  try {
    return await axios({
      method: "DELETE",
      url: ApiConfig[endPoint],
      headers: {
        token: window.sessionStorage.getItem("token"),
      },
      data: dataToSend ? dataToSend : null,
      params: paramsData ? paramsData : null,
    });
  } catch (error) {
    console.log(error);
    return error.response;
  }

  
};

export const getAPIHandler = async ({ endPoint, id, source, paramsData }) => {
  try {
    return await axios({
      method: "GET",
      url: id ? `${ApiConfig[endPoint]}/${id}` : ApiConfig[endPoint],
      params: paramsData ? paramsData : null,
      headers: {
        token: window.sessionStorage.getItem("token"),
      },
      cancelToken: source ? source.token : null,
    });
  } catch (error) {
    console.log(error);
  }
};


// export const getAPIHandlerWithToken = async ({endPoint, query, data}) => {
//   try {
//     const res = await axios({
//       method: "GET",
//       url: ApiConfig[endPoint],
//       headers: {
//         token: window.localStorage.getItem("token"),
//       },
//       params: query,
//       data: data,
//     });

//     if (res?.data?.responseCode === 200) {
//       return res.data;
//     } else {
//       alert(res?.data?.message);
//       return res;
//     }
//   } catch (error) {
//     if (error.response) {
//       return error.response;
//     } else {
//       return error;
//     }
//   }
// };

export const patchAPIHandler = async ({ endPoint, dataToSend, paramsData }) => {
  try {
    return await axios({
      method: "PATCH",
      url: ApiConfig[endPoint],
      headers: {
        token: window.sessionStorage.getItem("token"),
      },
      data: dataToSend ? dataToSend : null,
      params: paramsData ? paramsData : null,
    });
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const postAPIHandlerForFile = async ({ endPoint, dataToSend, paramsData }) => {
  try {
    const response = await axios({
      method: 'POST',
      url: ApiConfig[endPoint],
      headers: {
        'Content-Type': dataToSend instanceof FormData ? 'multipart/form-data' : 'application/json',
        token: window.sessionStorage.getItem('token'),
      },
      data: dataToSend || null,
      params: paramsData || null,
    });
    return response;
  } catch (error) {
    console.error("Error in API call:", error);
    return error.response || { status: 500, message: "Internal Server Error" };
  }
};
