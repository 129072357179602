import React from 'react';
import PropTypes from 'prop-types';

import './ExecutiveAnalysisCard.css';

const ExecutiveAnalysisCard = ({ backgroundImage, heading, count,  width, height }) => {
  const cardStyle = {
    background: `url(${backgroundImage}) center/cover`,
    width: width || '380.73px',
    height: height || '167.04px',
    borderRadius: '10px',
  };

  return (
    <div className='executive-analysis-card-container' style={cardStyle}>
      <h2 className='executive-analysis-card-heading'>{heading}</h2>
      <p className='executive-analysis-card-count'>{count}</p>
    </div>
  );
};

ExecutiveAnalysisCard.propTypes = {
  backgroundImage: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
  count: PropTypes.number.isRequired,
};

export default ExecutiveAnalysisCard;
